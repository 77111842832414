// Generated using j2ts version null on 2019-11-18 09:45:27.
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var common;
                (function (common) {
                    var spbm;
                    (function (spbm) {
                        var spec;
                        (function (spec) {
                            var QueryDrpSpec = (function () {
                                function QueryDrpSpec() {
                                    this._type = 'com.vmware.vsphere.client.common.spbm.spec.QueryDrpSpec';
                                }
                                return QueryDrpSpec;
                            }());
                            spec.QueryDrpSpec = QueryDrpSpec;
                        })(spec = spbm.spec || (spbm.spec = {}));
                    })(spbm = common.spbm || (common.spbm = {}));
                })(common = client.common || (client.common = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var mixed;
                (function (mixed) {
                    var ManagedEntityMoveSpec = (function () {
                        function ManagedEntityMoveSpec() {
                            this._type = 'com.vmware.vsphere.client.mixed.ManagedEntityMoveSpec';
                        }
                        return ManagedEntityMoveSpec;
                    }());
                    mixed.ManagedEntityMoveSpec = ManagedEntityMoveSpec;
                })(mixed = client.mixed || (client.mixed = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));




