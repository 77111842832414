// Generated using j2ts version null on 2019-11-18 09:45:56.
var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var mvc;
            (function (mvc) {
                var listview;
                (function (listview) {
                    var ListViewExportSpec = (function () {
                        function ListViewExportSpec() {
                            this._type = 'com.vmware.vise.mvc.listview.ListViewExportSpec';
                        }
                        return ListViewExportSpec;
                    }());
                    listview.ListViewExportSpec = ListViewExportSpec;
                })(listview = mvc.listview || (mvc.listview = {}));
            })(mvc = vise.mvc || (vise.mvc = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var data;
            (function (data) {
                var mutation;
                (function (mutation) {
                    var MutationOperationSpec = (function () {
                        function MutationOperationSpec() {
                            this._type = 'com.vmware.vise.data.mutation.MutationOperationSpec';
                        }
                        return MutationOperationSpec;
                    }());
                    mutation.MutationOperationSpec = MutationOperationSpec;
                })(mutation = data.mutation || (data.mutation = {}));
            })(data = vise.data || (vise.data = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var data;
            (function (data) {
                var scheduling;
                (function (scheduling) {
                    var ScheduledMutationOperationSpec = (function (_super) {
                        __extends(ScheduledMutationOperationSpec, _super);
                        function ScheduledMutationOperationSpec() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec';
                        }
                        return ScheduledMutationOperationSpec;
                    }(com.vmware.vise.data.mutation.MutationOperationSpec));
                    scheduling.ScheduledMutationOperationSpec = ScheduledMutationOperationSpec;
                })(scheduling = data.scheduling || (data.scheduling = {}));
            })(data = vise.data || (vise.data = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var mvc;
            (function (mvc) {
                var model;
                (function (model) {
                    var data;
                    (function (data) {
                        var PropertyPredicateSpec = (function () {
                            function PropertyPredicateSpec() {
                                this._type = 'com.vmware.vise.mvc.model.data.PropertyPredicateSpec';
                            }
                            return PropertyPredicateSpec;
                        }());
                        data.PropertyPredicateSpec = PropertyPredicateSpec;
                    })(data = model.data || (model.data = {}));
                })(model = mvc.model || (mvc.model = {}));
            })(mvc = vise.mvc || (vise.mvc = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var core;
            (function (core) {
                var model;
                (function (model) {
                    var scheduling;
                    (function (scheduling) {
                        var ScheduledTaskSpec = (function () {
                            function ScheduledTaskSpec() {
                                this._type = 'com.vmware.vise.core.model.scheduling.ScheduledTaskSpec';
                            }
                            return ScheduledTaskSpec;
                        }());
                        scheduling.ScheduledTaskSpec = ScheduledTaskSpec;
                    })(scheduling = model.scheduling || (model.scheduling = {}));
                })(model = core.model || (core.model = {}));
            })(core = vise.core || (vise.core = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var core;
            (function (core) {
                var model;
                (function (model) {
                    var ValidationResult = (function () {
                        function ValidationResult() {
                            this._type = 'com.vmware.vise.core.model.ValidationResult';
                        }
                        return ValidationResult;
                    }());
                    model.ValidationResult = ValidationResult;
                })(model = core.model || (core.model = {}));
            })(core = vise.core || (vise.core = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var mvc;
            (function (mvc) {
                var model;
                (function (model) {
                    var data;
                    (function (data) {
                        var FilterSpec = (function () {
                            function FilterSpec() {
                                this._type = 'com.vmware.vise.mvc.model.data.FilterSpec';
                            }
                            return FilterSpec;
                        }());
                        data.FilterSpec = FilterSpec;
                    })(data = model.data || (model.data = {}));
                })(model = mvc.model || (mvc.model = {}));
            })(mvc = vise.mvc || (vise.mvc = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var core;
            (function (core) {
                var model;
                (function (model) {
                    var OperationResult = (function () {
                        function OperationResult() {
                            this._type = 'com.vmware.vise.core.model.OperationResult';
                        }
                        return OperationResult;
                    }());
                    model.OperationResult = OperationResult;
                })(model = core.model || (core.model = {}));
            })(core = vise.core || (vise.core = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var actionsfw;
            (function (actionsfw) {
                var ActionContext = (function () {
                    function ActionContext() {
                        this._type = 'com.vmware.vise.actionsfw.ActionContext';
                    }
                    return ActionContext;
                }());
                actionsfw.ActionContext = ActionContext;
            })(actionsfw = vise.actionsfw || (vise.actionsfw = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vise;
        (function (vise) {
            var mvc;
            (function (mvc) {
                var model;
                (function (model) {
                    var data;
                    (function (data) {
                        var ParamSpec = (function () {
                            function ParamSpec() {
                                this._type = 'com.vmware.vise.mvc.model.data.ParamSpec';
                            }
                            return ParamSpec;
                        }());
                        data.ParamSpec = ParamSpec;
                    })(data = model.data || (model.data = {}));
                })(model = mvc.model || (mvc.model = {}));
            })(mvc = vise.mvc || (vise.mvc = {}));
        })(vise = vmware.vise || (vmware.vise = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));




