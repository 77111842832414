// Generated using j2ts version null on 2019-11-18 09:45:19.
var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_1) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var OptionValue = (function () {
                            function OptionValue() {
                                this._type = 'com.vmware.vim.binding.vim.option.OptionValue';
                            }
                            return OptionValue;
                        }());
                        option.OptionValue = OptionValue;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_1.binding || (vim_1.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_2) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var DistributedVirtualSwitch$ContactInfo = (function () {
                        function DistributedVirtualSwitch$ContactInfo() {
                            this._type = 'com.vmware.vim.binding.vim.DistributedVirtualSwitch$ContactInfo';
                        }
                        return DistributedVirtualSwitch$ContactInfo;
                    }());
                    vim.DistributedVirtualSwitch$ContactInfo = DistributedVirtualSwitch$ContactInfo;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_2.binding || (vim_2.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_3) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var PciDevice = (function () {
                            function PciDevice() {
                                this._type = 'com.vmware.vim.binding.vim.host.PciDevice';
                            }
                            return PciDevice;
                        }());
                        host.PciDevice = PciDevice;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_3.binding || (vim_3.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_4) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ServiceLocator = (function () {
                        function ServiceLocator() {
                            this._type = 'com.vmware.vim.binding.vim.ServiceLocator';
                        }
                        return ServiceLocator;
                    }());
                    vim.ServiceLocator = ServiceLocator;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_4.binding || (vim_4.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_5) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ResourcePool$RuntimeInfo = (function () {
                        function ResourcePool$RuntimeInfo() {
                            this._type = 'com.vmware.vim.binding.vim.ResourcePool$RuntimeInfo';
                        }
                        return ResourcePool$RuntimeInfo;
                    }());
                    vim.ResourcePool$RuntimeInfo = ResourcePool$RuntimeInfo;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_5.binding || (vim_5.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_6) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var CustomizationSpecInfo = (function () {
                        function CustomizationSpecInfo() {
                            this._type = 'com.vmware.vim.binding.vim.CustomizationSpecInfo';
                        }
                        return CustomizationSpecInfo;
                    }());
                    vim.CustomizationSpecInfo = CustomizationSpecInfo;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_6.binding || (vim_6.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_7) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var Datastore$Info = (function () {
                        function Datastore$Info() {
                            this._type = 'com.vmware.vim.binding.vim.Datastore$Info';
                        }
                        return Datastore$Info;
                    }());
                    vim.Datastore$Info = Datastore$Info;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_7.binding || (vim_7.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_8) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var IPSettings$IpV6AddressSpec = (function () {
                                function IPSettings$IpV6AddressSpec() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.IPSettings$IpV6AddressSpec';
                                }
                                return IPSettings$IpV6AddressSpec;
                            }());
                            customization.IPSettings$IpV6AddressSpec = IPSettings$IpV6AddressSpec;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_8.binding || (vim_8.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_9) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var ConfigInfo$FaultDomainInfo = (function () {
                                function ConfigInfo$FaultDomainInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.ConfigInfo$FaultDomainInfo';
                                }
                                return ConfigInfo$FaultDomainInfo;
                            }());
                            host.ConfigInfo$FaultDomainInfo = ConfigInfo$FaultDomainInfo;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_9.binding || (vim_9.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_10) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$SendTarget = (function () {
                            function InternetScsiHba$SendTarget() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$SendTarget';
                            }
                            return InternetScsiHba$SendTarget;
                        }());
                        host.InternetScsiHba$SendTarget = InternetScsiHba$SendTarget;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_10.binding || (vim_10.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_11) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FlagInfo = (function () {
                            function FlagInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FlagInfo';
                            }
                            return FlagInfo;
                        }());
                        vm.FlagInfo = FlagInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_11.binding || (vim_11.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_12) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var CustomFieldsManager$Value = (function () {
                        function CustomFieldsManager$Value() {
                            this._type = 'com.vmware.vim.binding.vim.CustomFieldsManager$Value';
                        }
                        return CustomFieldsManager$Value;
                    }());
                    vim.CustomFieldsManager$Value = CustomFieldsManager$Value;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_12.binding || (vim_12.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_13) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$PvlanConfigSpec = (function () {
                            function VmwareDistributedVirtualSwitch$PvlanConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanConfigSpec';
                            }
                            return VmwareDistributedVirtualSwitch$PvlanConfigSpec;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$PvlanConfigSpec = VmwareDistributedVirtualSwitch$PvlanConfigSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_13.binding || (vim_13.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_14) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var IpRouteConfig = (function () {
                            function IpRouteConfig() {
                                this._type = 'com.vmware.vim.binding.vim.host.IpRouteConfig';
                            }
                            return IpRouteConfig;
                        }());
                        host.IpRouteConfig = IpRouteConfig;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_14.binding || (vim_14.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var capability;
                    (function (capability) {
                        var CapabilityMetadata$UniqueId = (function () {
                            function CapabilityMetadata$UniqueId() {
                                this._type = 'com.vmware.vim.binding.pbm.capability.CapabilityMetadata$UniqueId';
                            }
                            return CapabilityMetadata$UniqueId;
                        }());
                        capability.CapabilityMetadata$UniqueId = CapabilityMetadata$UniqueId;
                    })(capability = pbm.capability || (pbm.capability = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var profile;
                    (function (profile) {
                        var SubProfileCapabilityConstraints$SubProfile = (function () {
                            function SubProfileCapabilityConstraints$SubProfile() {
                                this._type = 'com.vmware.vim.binding.pbm.profile.SubProfileCapabilityConstraints$SubProfile';
                            }
                            return SubProfileCapabilityConstraints$SubProfile;
                        }());
                        profile.SubProfileCapabilityConstraints$SubProfile = SubProfileCapabilityConstraints$SubProfile;
                    })(profile = pbm.profile || (pbm.profile = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_15) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceSpec = (function () {
                                function VirtualDeviceSpec() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec';
                                }
                                return VirtualDeviceSpec;
                            }());
                            device.VirtualDeviceSpec = VirtualDeviceSpec;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_15.binding || (vim_15.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_16) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var ConfigInfo$NetworkInfo = (function () {
                                function ConfigInfo$NetworkInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.ConfigInfo$NetworkInfo';
                                }
                                return ConfigInfo$NetworkInfo;
                            }());
                            host.ConfigInfo$NetworkInfo = ConfigInfo$NetworkInfo;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_16.binding || (vim_16.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_17) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConsolePreferences = (function () {
                            function ConsolePreferences() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConsolePreferences';
                            }
                            return ConsolePreferences;
                        }());
                        vm.ConsolePreferences = ConsolePreferences;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_17.binding || (vim_17.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_18) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ToolsConfigInfo$ToolsLastInstallInfo = (function () {
                            function ToolsConfigInfo$ToolsLastInstallInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ToolsConfigInfo$ToolsLastInstallInfo';
                            }
                            return ToolsConfigInfo$ToolsLastInstallInfo;
                        }());
                        vm.ToolsConfigInfo$ToolsLastInstallInfo = ToolsConfigInfo$ToolsLastInstallInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_18.binding || (vim_18.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_19) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var StorageResourceManager$IOAllocationInfo = (function () {
                        function StorageResourceManager$IOAllocationInfo() {
                            this._type = 'com.vmware.vim.binding.vim.StorageResourceManager$IOAllocationInfo';
                        }
                        return StorageResourceManager$IOAllocationInfo;
                    }());
                    vim.StorageResourceManager$IOAllocationInfo = StorageResourceManager$IOAllocationInfo;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_19.binding || (vim_19.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_20) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var NegatableExpression = (function () {
                        function NegatableExpression() {
                            this._type = 'com.vmware.vim.binding.vim.NegatableExpression';
                        }
                        return NegatableExpression;
                    }());
                    vim.NegatableExpression = NegatableExpression;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_20.binding || (vim_20.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_21) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var PhysicalNic$LinkSpeedDuplex = (function () {
                            function PhysicalNic$LinkSpeedDuplex() {
                                this._type = 'com.vmware.vim.binding.vim.host.PhysicalNic$LinkSpeedDuplex';
                            }
                            return PhysicalNic$LinkSpeedDuplex;
                        }());
                        host.PhysicalNic$LinkSpeedDuplex = PhysicalNic$LinkSpeedDuplex;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_21.binding || (vim_21.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_22) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var Description = (function () {
                        function Description() {
                            this._type = 'com.vmware.vim.binding.vim.Description';
                        }
                        return Description;
                    }());
                    vim.Description = Description;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_22.binding || (vim_22.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_23) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var RuntimeInfo = (function () {
                            function RuntimeInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.RuntimeInfo';
                            }
                            return RuntimeInfo;
                        }());
                        vm.RuntimeInfo = RuntimeInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_23.binding || (vim_23.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_24) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var NumericRange = (function () {
                        function NumericRange() {
                            this._type = 'com.vmware.vim.binding.vim.NumericRange';
                        }
                        return NumericRange;
                    }());
                    vim.NumericRange = NumericRange;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_24.binding || (vim_24.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_25) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var VirtualHardwareOption = (function () {
                            function VirtualHardwareOption() {
                                this._type = 'com.vmware.vim.binding.vim.vm.VirtualHardwareOption';
                            }
                            return VirtualHardwareOption;
                        }());
                        vm.VirtualHardwareOption = VirtualHardwareOption;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_25.binding || (vim_25.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_26) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var storageDrs;
                    (function (storageDrs) {
                        var PodSelectionSpec = (function () {
                            function PodSelectionSpec() {
                                this._type = 'com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec';
                            }
                            return PodSelectionSpec;
                        }());
                        storageDrs.PodSelectionSpec = PodSelectionSpec;
                    })(storageDrs = vim.storageDrs || (vim.storageDrs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_26.binding || (vim_26.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_27) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var Capability = (function () {
                            function Capability() {
                                this._type = 'com.vmware.vim.binding.vim.vm.Capability';
                            }
                            return Capability;
                        }());
                        vm.Capability = Capability;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_27.binding || (vim_27.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_28) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualEthernetCard$ResourceAllocation = (function () {
                                function VirtualEthernetCard$ResourceAllocation() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$ResourceAllocation';
                                }
                                return VirtualEthernetCard$ResourceAllocation;
                            }());
                            device.VirtualEthernetCard$ResourceAllocation = VirtualEthernetCard$ResourceAllocation;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_28.binding || (vim_28.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_29) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DatastoreOption$FileSystemVolumeOption = (function () {
                            function DatastoreOption$FileSystemVolumeOption() {
                                this._type = 'com.vmware.vim.binding.vim.vm.DatastoreOption$FileSystemVolumeOption';
                            }
                            return DatastoreOption$FileSystemVolumeOption;
                        }());
                        vm.DatastoreOption$FileSystemVolumeOption = DatastoreOption$FileSystemVolumeOption;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_29.binding || (vim_29.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_30) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DasVmSettings = (function () {
                            function DasVmSettings() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DasVmSettings';
                            }
                            return DasVmSettings;
                        }());
                        cluster.DasVmSettings = DasVmSettings;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_30.binding || (vim_30.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_31) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DefaultPowerOpInfo = (function () {
                            function DefaultPowerOpInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.DefaultPowerOpInfo';
                            }
                            return DefaultPowerOpInfo;
                        }());
                        vm.DefaultPowerOpInfo = DefaultPowerOpInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_31.binding || (vim_31.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_32) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var replication;
                        (function (replication) {
                            var DeviceGroupId = (function () {
                                function DeviceGroupId() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.replication.DeviceGroupId';
                                }
                                return DeviceGroupId;
                            }());
                            replication.DeviceGroupId = DeviceGroupId;
                        })(replication = vm.replication || (vm.replication = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_32.binding || (vim_32.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_33) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var HostMember$Backing = (function () {
                            function HostMember$Backing() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.HostMember$Backing';
                            }
                            return HostMember$Backing;
                        }());
                        dvs.HostMember$Backing = HostMember$Backing;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_33.binding || (vim_33.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_34) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var SharesInfo = (function () {
                        function SharesInfo() {
                            this._type = 'com.vmware.vim.binding.vim.SharesInfo';
                        }
                        return SharesInfo;
                    }());
                    vim.SharesInfo = SharesInfo;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_34.binding || (vim_34.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_35) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var HostMember$ConfigSpec = (function () {
                            function HostMember$ConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.HostMember$ConfigSpec';
                            }
                            return HostMember$ConfigSpec;
                        }());
                        dvs.HostMember$ConfigSpec = HostMember$ConfigSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_35.binding || (vim_35.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var capability;
                    (function (capability) {
                        var CapabilityInstance = (function () {
                            function CapabilityInstance() {
                                this._type = 'com.vmware.vim.binding.pbm.capability.CapabilityInstance';
                            }
                            return CapabilityInstance;
                        }());
                        capability.CapabilityInstance = CapabilityInstance;
                    })(capability = pbm.capability || (pbm.capability = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_36) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$AuthenticationProperties = (function () {
                            function InternetScsiHba$AuthenticationProperties() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$AuthenticationProperties';
                            }
                            return InternetScsiHba$AuthenticationProperties;
                        }());
                        host.InternetScsiHba$AuthenticationProperties = InternetScsiHba$AuthenticationProperties;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_36.binding || (vim_36.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_37) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var LatencySensitivity = (function () {
                        function LatencySensitivity() {
                            this._type = 'com.vmware.vim.binding.vim.LatencySensitivity';
                        }
                        return LatencySensitivity;
                    }());
                    vim.LatencySensitivity = LatencySensitivity;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_37.binding || (vim_37.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_38) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var ProfilePropertyPath = (function () {
                            function ProfilePropertyPath() {
                                this._type = 'com.vmware.vim.binding.vim.profile.ProfilePropertyPath';
                            }
                            return ProfilePropertyPath;
                        }());
                        profile.ProfilePropertyPath = ProfilePropertyPath;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_38.binding || (vim_38.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_39) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$Qualifier = (function () {
                            function TrafficRule$Qualifier() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$Qualifier';
                            }
                            return TrafficRule$Qualifier;
                        }());
                        dvs.TrafficRule$Qualifier = TrafficRule$Qualifier;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_39.binding || (vim_39.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_40) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$MacQualifier = (function (_super) {
                            __extends(TrafficRule$MacQualifier, _super);
                            function TrafficRule$MacQualifier() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$MacQualifier';
                            }
                            return TrafficRule$MacQualifier;
                        }(dvs.TrafficRule$Qualifier));
                        dvs.TrafficRule$MacQualifier = TrafficRule$MacQualifier;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_40.binding || (vim_40.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_41) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$IpQualifier = (function (_super) {
                            __extends(TrafficRule$IpQualifier, _super);
                            function TrafficRule$IpQualifier() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$IpQualifier';
                            }
                            return TrafficRule$IpQualifier;
                        }(dvs.TrafficRule$Qualifier));
                        dvs.TrafficRule$IpQualifier = TrafficRule$IpQualifier;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_41.binding || (vim_41.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_42) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$IpfixConfig = (function () {
                            function VmwareDistributedVirtualSwitch$IpfixConfig() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$IpfixConfig';
                            }
                            return VmwareDistributedVirtualSwitch$IpfixConfig;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$IpfixConfig = VmwareDistributedVirtualSwitch$IpfixConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_42.binding || (vim_42.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_43) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var IpConfig$IpV6Address = (function () {
                            function IpConfig$IpV6Address() {
                                this._type = 'com.vmware.vim.binding.vim.host.IpConfig$IpV6Address';
                            }
                            return IpConfig$IpV6Address;
                        }());
                        host.IpConfig$IpV6Address = IpConfig$IpV6Address;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_43.binding || (vim_43.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_44) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var ApplyProfile = (function () {
                            function ApplyProfile() {
                                this._type = 'com.vmware.vim.binding.vim.profile.ApplyProfile';
                            }
                            return ApplyProfile;
                        }());
                        profile.ApplyProfile = ApplyProfile;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_44.binding || (vim_44.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_45) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var host;
                        (function (host) {
                            var StorageProfile = (function (_super) {
                                __extends(StorageProfile, _super);
                                function StorageProfile() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.profile.host.StorageProfile';
                                }
                                return StorageProfile;
                            }(com.vmware.vim.binding.vim.profile.ApplyProfile));
                            host.StorageProfile = StorageProfile;
                        })(host = profile.host || (profile.host = {}));
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_45.binding || (vim_45.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_46) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var IntExpression = (function (_super) {
                        __extends(IntExpression, _super);
                        function IntExpression() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.IntExpression';
                        }
                        return IntExpression;
                    }(vim.NegatableExpression));
                    vim.IntExpression = IntExpression;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_46.binding || (vim_46.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_47) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$LagVlanConfig = (function () {
                            function VmwareDistributedVirtualSwitch$LagVlanConfig() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LagVlanConfig';
                            }
                            return VmwareDistributedVirtualSwitch$LagVlanConfig;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$LagVlanConfig = VmwareDistributedVirtualSwitch$LagVlanConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_47.binding || (vim_47.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_48) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var encryption;
                    (function (encryption) {
                        var CryptoSpec = (function () {
                            function CryptoSpec() {
                                this._type = 'com.vmware.vim.binding.vim.encryption.CryptoSpec';
                            }
                            return CryptoSpec;
                        }());
                        encryption.CryptoSpec = CryptoSpec;
                    })(encryption = vim.encryption || (vim.encryption = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_48.binding || (vim_48.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_49) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$IpPort = (function (_super) {
                            __extends(TrafficRule$IpPort, _super);
                            function TrafficRule$IpPort() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$IpPort';
                            }
                            return TrafficRule$IpPort;
                        }(com.vmware.vim.binding.vim.NegatableExpression));
                        dvs.TrafficRule$IpPort = TrafficRule$IpPort;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_49.binding || (vim_49.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_50) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var CustomizationSpecItem = (function () {
                        function CustomizationSpecItem() {
                            this._type = 'com.vmware.vim.binding.vim.CustomizationSpecItem';
                        }
                        return CustomizationSpecItem;
                    }());
                    vim.CustomizationSpecItem = CustomizationSpecItem;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_50.binding || (vim_50.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_51) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var encryption;
                    (function (encryption) {
                        var KeyProviderId = (function () {
                            function KeyProviderId() {
                                this._type = 'com.vmware.vim.binding.vim.encryption.KeyProviderId';
                            }
                            return KeyProviderId;
                        }());
                        encryption.KeyProviderId = KeyProviderId;
                    })(encryption = vim.encryption || (vim.encryption = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_51.binding || (vim_51.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_52) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ResourceAllocationOption = (function () {
                        function ResourceAllocationOption() {
                            this._type = 'com.vmware.vim.binding.vim.ResourceAllocationOption';
                        }
                        return ResourceAllocationOption;
                    }());
                    vim.ResourceAllocationOption = ResourceAllocationOption;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_52.binding || (vim_52.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_53) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var Capability = (function () {
                            function Capability() {
                                this._type = 'com.vmware.vim.binding.vim.host.Capability';
                            }
                            return Capability;
                        }());
                        host.Capability = Capability;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_53.binding || (vim_53.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_54) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var Datastore$Summary = (function () {
                        function Datastore$Summary() {
                            this._type = 'com.vmware.vim.binding.vim.Datastore$Summary';
                        }
                        return Datastore$Summary;
                    }());
                    vim.Datastore$Summary = Datastore$Summary;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_54.binding || (vim_54.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_55) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var DistributedVirtualSwitch$HostInfrastructureTrafficResource = (function () {
                        function DistributedVirtualSwitch$HostInfrastructureTrafficResource() {
                            this._type = 'com.vmware.vim.binding.vim.DistributedVirtualSwitch$HostInfrastructureTrafficResource';
                        }
                        return DistributedVirtualSwitch$HostInfrastructureTrafficResource;
                    }());
                    vim.DistributedVirtualSwitch$HostInfrastructureTrafficResource = DistributedVirtualSwitch$HostInfrastructureTrafficResource;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_55.binding || (vim_55.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_56) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var SriovDevicePoolInfo = (function () {
                            function SriovDevicePoolInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.SriovDevicePoolInfo';
                            }
                            return SriovDevicePoolInfo;
                        }());
                        vm.SriovDevicePoolInfo = SriovDevicePoolInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_56.binding || (vim_56.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_57) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigSpec = (function () {
                            function ConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigSpec';
                            }
                            return ConfigSpec;
                        }());
                        vm.ConfigSpec = ConfigSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_57.binding || (vim_57.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_58) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$Setting = (function () {
                            function DistributedVirtualPort$Setting() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$Setting';
                            }
                            return DistributedVirtualPort$Setting;
                        }());
                        dvs.DistributedVirtualPort$Setting = DistributedVirtualPort$Setting;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_58.binding || (vim_58.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_59) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var LicenseFilePrintData = (function () {
                                function LicenseFilePrintData() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.LicenseFilePrintData';
                                }
                                return LicenseFilePrintData;
                            }());
                            customization.LicenseFilePrintData = LicenseFilePrintData;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_59.binding || (vim_59.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_60) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption$BackingOption = (function () {
                                function VirtualDeviceOption$BackingOption() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$BackingOption';
                                }
                                return VirtualDeviceOption$BackingOption;
                            }());
                            device.VirtualDeviceOption$BackingOption = VirtualDeviceOption$BackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_60.binding || (vim_60.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_61) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FileInfo = (function () {
                            function FileInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FileInfo';
                            }
                            return FileInfo;
                        }());
                        vm.FileInfo = FileInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_61.binding || (vim_61.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_62) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var storageDrs;
                    (function (storageDrs) {
                        var PodSelectionSpec$VmPodConfig = (function () {
                            function PodSelectionSpec$VmPodConfig() {
                                this._type = 'com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec$VmPodConfig';
                            }
                            return PodSelectionSpec$VmPodConfig;
                        }());
                        storageDrs.PodSelectionSpec$VmPodConfig = PodSelectionSpec$VmPodConfig;
                    })(storageDrs = vim.storageDrs || (vim.storageDrs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_62.binding || (vim_62.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_63) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var ProductSpec = (function () {
                            function ProductSpec() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.ProductSpec';
                            }
                            return ProductSpec;
                        }());
                        dvs.ProductSpec = ProductSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_63.binding || (vim_63.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_64) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VspanConfigSpec = (function () {
                            function VmwareDistributedVirtualSwitch$VspanConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VspanConfigSpec';
                            }
                            return VmwareDistributedVirtualSwitch$VspanConfigSpec;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$VspanConfigSpec = VmwareDistributedVirtualSwitch$VspanConfigSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_64.binding || (vim_64.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_65) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPortgroup$PortgroupPolicy = (function () {
                            function DistributedVirtualPortgroup$PortgroupPolicy() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$PortgroupPolicy';
                            }
                            return DistributedVirtualPortgroup$PortgroupPolicy;
                        }());
                        dvs.DistributedVirtualPortgroup$PortgroupPolicy = DistributedVirtualPortgroup$PortgroupPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_65.binding || (vim_65.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_66) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var Summary = (function () {
                            function Summary() {
                                this._type = 'com.vmware.vim.binding.vim.vm.Summary';
                            }
                            return Summary;
                        }());
                        vm.Summary = Summary;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_66.binding || (vim_66.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_67) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigOptionDescriptor = (function () {
                            function ConfigOptionDescriptor() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigOptionDescriptor';
                            }
                            return ConfigOptionDescriptor;
                        }());
                        vm.ConfigOptionDescriptor = ConfigOptionDescriptor;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_67.binding || (vim_67.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_68) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var PortConnection = (function () {
                            function PortConnection() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.PortConnection';
                            }
                            return PortConnection;
                        }());
                        dvs.PortConnection = PortConnection;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_68.binding || (vim_68.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_69) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption$URIBackingOption = (function (_super) {
                                __extends(VirtualDeviceOption$URIBackingOption, _super);
                                function VirtualDeviceOption$URIBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$URIBackingOption';
                                }
                                return VirtualDeviceOption$URIBackingOption;
                            }(device.VirtualDeviceOption$BackingOption));
                            device.VirtualDeviceOption$URIBackingOption = VirtualDeviceOption$URIBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_69.binding || (vim_69.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_70) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var ParameterMetadata$ParameterRelationMetadata = (function () {
                            function ParameterMetadata$ParameterRelationMetadata() {
                                this._type = 'com.vmware.vim.binding.vim.profile.ParameterMetadata$ParameterRelationMetadata';
                            }
                            return ParameterMetadata$ParameterRelationMetadata;
                        }());
                        profile.ParameterMetadata$ParameterRelationMetadata = ParameterMetadata$ParameterRelationMetadata;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_70.binding || (vim_70.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_71) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$Action = (function () {
                            function TrafficRule$Action() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$Action';
                            }
                            return TrafficRule$Action;
                        }());
                        dvs.TrafficRule$Action = TrafficRule$Action;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_71.binding || (vim_71.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_72) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ForceMountedInfo = (function () {
                            function ForceMountedInfo() {
                                this._type = 'com.vmware.vim.binding.vim.host.ForceMountedInfo';
                            }
                            return ForceMountedInfo;
                        }());
                        host.ForceMountedInfo = ForceMountedInfo;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_72.binding || (vim_72.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_73) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DasVmConfigInfo = (function () {
                            function DasVmConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DasVmConfigInfo';
                            }
                            return DasVmConfigInfo;
                        }());
                        cluster.DasVmConfigInfo = DasVmConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_73.binding || (vim_73.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_74) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var IdentitySettings = (function () {
                                function IdentitySettings() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.IdentitySettings';
                                }
                                return IdentitySettings;
                            }());
                            customization.IdentitySettings = IdentitySettings;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_74.binding || (vim_74.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_75) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var SysprepText = (function (_super) {
                                __extends(SysprepText, _super);
                                function SysprepText() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.SysprepText';
                                }
                                return SysprepText;
                            }(customization.IdentitySettings));
                            customization.SysprepText = SysprepText;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_75.binding || (vim_75.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_76) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var Sysprep = (function (_super) {
                                __extends(Sysprep, _super);
                                function Sysprep() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.Sysprep';
                                }
                                return Sysprep;
                            }(customization.IdentitySettings));
                            customization.Sysprep = Sysprep;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_76.binding || (vim_76.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_77) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var LinuxPrep = (function (_super) {
                                __extends(LinuxPrep, _super);
                                function LinuxPrep() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.LinuxPrep';
                                }
                                return LinuxPrep;
                            }(customization.IdentitySettings));
                            customization.LinuxPrep = LinuxPrep;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_77.binding || (vim_77.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_78) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var GuiRunOnce = (function () {
                                function GuiRunOnce() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.GuiRunOnce';
                                }
                                return GuiRunOnce;
                            }());
                            customization.GuiRunOnce = GuiRunOnce;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_78.binding || (vim_78.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_79) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var NameGenerator = (function () {
                                function NameGenerator() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.NameGenerator';
                                }
                                return NameGenerator;
                            }());
                            customization.NameGenerator = NameGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_79.binding || (vim_79.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_80) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var VirtualMachineNameGenerator = (function (_super) {
                                __extends(VirtualMachineNameGenerator, _super);
                                function VirtualMachineNameGenerator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.VirtualMachineNameGenerator';
                                }
                                return VirtualMachineNameGenerator;
                            }(customization.NameGenerator));
                            customization.VirtualMachineNameGenerator = VirtualMachineNameGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_80.binding || (vim_80.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_81) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var PrefixNameGenerator = (function (_super) {
                                __extends(PrefixNameGenerator, _super);
                                function PrefixNameGenerator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.PrefixNameGenerator';
                                }
                                return PrefixNameGenerator;
                            }(customization.NameGenerator));
                            customization.PrefixNameGenerator = PrefixNameGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_81.binding || (vim_81.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_82) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var PolicyOption = (function () {
                            function PolicyOption() {
                                this._type = 'com.vmware.vim.binding.vim.profile.PolicyOption';
                            }
                            return PolicyOption;
                        }());
                        profile.PolicyOption = PolicyOption;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_82.binding || (vim_82.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var profile;
                    (function (profile) {
                        var Profile = (function () {
                            function Profile() {
                                this._type = 'com.vmware.vim.binding.pbm.profile.Profile';
                            }
                            return Profile;
                        }());
                        profile.Profile = Profile;
                    })(profile = pbm.profile || (pbm.profile = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_83) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var DiskMapInfo = (function () {
                                function DiskMapInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.DiskMapInfo';
                                }
                                return DiskMapInfo;
                            }());
                            host.DiskMapInfo = DiskMapInfo;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_83.binding || (vim_83.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_84) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy, _super);
                            function VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy;
                        }(dvs.DistributedVirtualPort$Setting));
                        dvs.VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy = VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_84.binding || (vim_84.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_85) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var AdapterMapping = (function () {
                                function AdapterMapping() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.AdapterMapping';
                                }
                                return AdapterMapping;
                            }());
                            customization.AdapterMapping = AdapterMapping;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_85.binding || (vim_85.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_86) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$UpdateTagAction = (function (_super) {
                            __extends(TrafficRule$UpdateTagAction, _super);
                            function TrafficRule$UpdateTagAction() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$UpdateTagAction';
                            }
                            return TrafficRule$UpdateTagAction;
                        }(dvs.TrafficRule$Action));
                        dvs.TrafficRule$UpdateTagAction = TrafficRule$UpdateTagAction;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_86.binding || (vim_86.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_87) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ResourceConfigOption = (function () {
                        function ResourceConfigOption() {
                            this._type = 'com.vmware.vim.binding.vim.ResourceConfigOption';
                        }
                        return ResourceConfigOption;
                    }());
                    vim.ResourceConfigOption = ResourceConfigOption;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_87.binding || (vim_87.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_88) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var DistributedVirtualSwitch$ConfigSpec = (function () {
                        function DistributedVirtualSwitch$ConfigSpec() {
                            this._type = 'com.vmware.vim.binding.vim.DistributedVirtualSwitch$ConfigSpec';
                        }
                        return DistributedVirtualSwitch$ConfigSpec;
                    }());
                    vim.DistributedVirtualSwitch$ConfigSpec = DistributedVirtualSwitch$ConfigSpec;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_88.binding || (vim_88.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_89) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ProfileRawData = (function () {
                            function ProfileRawData() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ProfileRawData';
                            }
                            return ProfileRawData;
                        }());
                        vm.ProfileRawData = ProfileRawData;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_89.binding || (vim_89.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_90) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var replication;
                        (function (replication) {
                            var ReplicationGroupId = (function () {
                                function ReplicationGroupId() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.replication.ReplicationGroupId';
                                }
                                return ReplicationGroupId;
                            }());
                            replication.ReplicationGroupId = ReplicationGroupId;
                        })(replication = vm.replication || (vm.replication = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_90.binding || (vim_90.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_91) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var storageDrs;
                    (function (storageDrs) {
                        var StoragePlacementResult = (function () {
                            function StoragePlacementResult() {
                                this._type = 'com.vmware.vim.binding.vim.storageDrs.StoragePlacementResult';
                            }
                            return StoragePlacementResult;
                        }());
                        storageDrs.StoragePlacementResult = StoragePlacementResult;
                    })(storageDrs = vim.storageDrs || (vim.storageDrs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_91.binding || (vim_91.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_92) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var IPAssignmentInfo = (function () {
                            function IPAssignmentInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vApp.IPAssignmentInfo';
                            }
                            return IPAssignmentInfo;
                        }());
                        vApp.IPAssignmentInfo = IPAssignmentInfo;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_92.binding || (vim_92.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var capability;
                    (function (capability) {
                        var PropertyInstance = (function () {
                            function PropertyInstance() {
                                this._type = 'com.vmware.vim.binding.pbm.capability.PropertyInstance';
                            }
                            return PropertyInstance;
                        }());
                        capability.PropertyInstance = PropertyInstance;
                    })(capability = pbm.capability || (pbm.capability = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_93) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DrsConfigInfo = (function () {
                            function DrsConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DrsConfigInfo';
                            }
                            return DrsConfigInfo;
                        }());
                        cluster.DrsConfigInfo = DrsConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_93.binding || (vim_93.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_94) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var DistributedVirtualSwitch$UplinkPortPolicy = (function () {
                        function DistributedVirtualSwitch$UplinkPortPolicy() {
                            this._type = 'com.vmware.vim.binding.vim.DistributedVirtualSwitch$UplinkPortPolicy';
                        }
                        return DistributedVirtualSwitch$UplinkPortPolicy;
                    }());
                    vim.DistributedVirtualSwitch$UplinkPortPolicy = DistributedVirtualSwitch$UplinkPortPolicy;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_94.binding || (vim_94.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_95) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var VmfsVolume$UnmapBandwidthSpec = (function () {
                            function VmfsVolume$UnmapBandwidthSpec() {
                                this._type = 'com.vmware.vim.binding.vim.host.VmfsVolume$UnmapBandwidthSpec';
                            }
                            return VmfsVolume$UnmapBandwidthSpec;
                        }());
                        host.VmfsVolume$UnmapBandwidthSpec = VmfsVolume$UnmapBandwidthSpec;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_95.binding || (vim_95.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var impl;
                (function (impl) {
                    var vmodl;
                    (function (vmodl) {
                        var TypeNameImpl = (function () {
                            function TypeNameImpl() {
                                this._type = 'com.vmware.vim.binding.impl.vmodl.TypeNameImpl';
                            }
                            return TypeNameImpl;
                        }());
                        vmodl.TypeNameImpl = TypeNameImpl;
                    })(vmodl = impl.vmodl || (impl.vmodl = {}));
                })(impl = binding.impl || (binding.impl = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_96) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var SriovNetworkDevicePoolInfo = (function (_super) {
                            __extends(SriovNetworkDevicePoolInfo, _super);
                            function SriovNetworkDevicePoolInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.SriovNetworkDevicePoolInfo';
                            }
                            return SriovNetworkDevicePoolInfo;
                        }(vm.SriovDevicePoolInfo));
                        vm.SriovNetworkDevicePoolInfo = SriovNetworkDevicePoolInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_96.binding || (vim_96.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_97) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$ConnectInfo = (function () {
                                function VirtualDevice$ConnectInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$ConnectInfo';
                                }
                                return VirtualDevice$ConnectInfo;
                            }());
                            device.VirtualDevice$ConnectInfo = VirtualDevice$ConnectInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_97.binding || (vim_97.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_98) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var ApplyProfileProperty = (function () {
                            function ApplyProfileProperty() {
                                this._type = 'com.vmware.vim.binding.vim.profile.ApplyProfileProperty';
                            }
                            return ApplyProfileProperty;
                        }());
                        profile.ApplyProfileProperty = ApplyProfileProperty;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_98.binding || (vim_98.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_99) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceSpec$BackingSpec = (function () {
                                function VirtualDeviceSpec$BackingSpec() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec$BackingSpec';
                                }
                                return VirtualDeviceSpec$BackingSpec;
                            }());
                            device.VirtualDeviceSpec$BackingSpec = VirtualDeviceSpec$BackingSpec;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_99.binding || (vim_99.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_100) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var CpuIdInfo = (function () {
                            function CpuIdInfo() {
                                this._type = 'com.vmware.vim.binding.vim.host.CpuIdInfo';
                            }
                            return CpuIdInfo;
                        }());
                        host.CpuIdInfo = CpuIdInfo;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_100.binding || (vim_100.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_101) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var FileSystemVolume = (function () {
                            function FileSystemVolume() {
                                this._type = 'com.vmware.vim.binding.vim.host.FileSystemVolume';
                            }
                            return FileSystemVolume;
                        }());
                        host.FileSystemVolume = FileSystemVolume;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_101.binding || (vim_101.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_102) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption = (function () {
                                function VirtualDeviceOption() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption';
                                }
                                return VirtualDeviceOption;
                            }());
                            device.VirtualDeviceOption = VirtualDeviceOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_102.binding || (vim_102.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_103) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualControllerOption = (function (_super) {
                                __extends(VirtualControllerOption, _super);
                                function VirtualControllerOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualControllerOption';
                                }
                                return VirtualControllerOption;
                            }(device.VirtualDeviceOption));
                            device.VirtualControllerOption = VirtualControllerOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_103.binding || (vim_103.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_104) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualNVDIMMControllerOption = (function (_super) {
                                __extends(VirtualNVDIMMControllerOption, _super);
                                function VirtualNVDIMMControllerOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualNVDIMMControllerOption';
                                }
                                return VirtualNVDIMMControllerOption;
                            }(device.VirtualControllerOption));
                            device.VirtualNVDIMMControllerOption = VirtualNVDIMMControllerOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_104.binding || (vim_104.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_105) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualEthernetCardOption = (function (_super) {
                                __extends(VirtualEthernetCardOption, _super);
                                function VirtualEthernetCardOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCardOption';
                                }
                                return VirtualEthernetCardOption;
                            }(device.VirtualDeviceOption));
                            device.VirtualEthernetCardOption = VirtualEthernetCardOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_105.binding || (vim_105.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_106) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPortOption = (function (_super) {
                                __extends(VirtualSerialPortOption, _super);
                                function VirtualSerialPortOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption';
                                }
                                return VirtualSerialPortOption;
                            }(device.VirtualDeviceOption));
                            device.VirtualSerialPortOption = VirtualSerialPortOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_106.binding || (vim_106.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_107) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthroughOption = (function (_super) {
                                __extends(VirtualPCIPassthroughOption, _super);
                                function VirtualPCIPassthroughOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption';
                                }
                                return VirtualPCIPassthroughOption;
                            }(device.VirtualDeviceOption));
                            device.VirtualPCIPassthroughOption = VirtualPCIPassthroughOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_107.binding || (vim_107.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_108) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FaultToleranceConfigInfo = (function () {
                            function FaultToleranceConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FaultToleranceConfigInfo';
                            }
                            return FaultToleranceConfigInfo;
                        }());
                        vm.FaultToleranceConfigInfo = FaultToleranceConfigInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_108.binding || (vim_108.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_109) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigOption = (function () {
                            function ConfigOption() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigOption';
                            }
                            return ConfigOption;
                        }());
                        vm.ConfigOption = ConfigOption;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_109.binding || (vim_109.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_110) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var Network$Summary = (function () {
                        function Network$Summary() {
                            this._type = 'com.vmware.vim.binding.vim.Network$Summary';
                        }
                        return Network$Summary;
                    }());
                    vim.Network$Summary = Network$Summary;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_110.binding || (vim_110.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_111) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var OpaqueNetwork$Summary = (function (_super) {
                        __extends(OpaqueNetwork$Summary, _super);
                        function OpaqueNetwork$Summary() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.OpaqueNetwork$Summary';
                        }
                        return OpaqueNetwork$Summary;
                    }(vim.Network$Summary));
                    vim.OpaqueNetwork$Summary = OpaqueNetwork$Summary;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_111.binding || (vim_111.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_112) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$IPCapabilities = (function () {
                            function InternetScsiHba$IPCapabilities() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$IPCapabilities';
                            }
                            return InternetScsiHba$IPCapabilities;
                        }());
                        host.InternetScsiHba$IPCapabilities = InternetScsiHba$IPCapabilities;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_112.binding || (vim_112.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_113) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var IdeDiskDeviceInfo$PartitionInfo = (function () {
                            function IdeDiskDeviceInfo$PartitionInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.IdeDiskDeviceInfo$PartitionInfo';
                            }
                            return IdeDiskDeviceInfo$PartitionInfo;
                        }());
                        vm.IdeDiskDeviceInfo$PartitionInfo = IdeDiskDeviceInfo$PartitionInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_113.binding || (vim_113.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_114) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption$BusSlotOption = (function () {
                                function VirtualDeviceOption$BusSlotOption() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$BusSlotOption';
                                }
                                return VirtualDeviceOption$BusSlotOption;
                            }());
                            device.VirtualDeviceOption$BusSlotOption = VirtualDeviceOption$BusSlotOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_114.binding || (vim_114.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_115) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var GuiUnattended = (function () {
                                function GuiUnattended() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.GuiUnattended';
                                }
                                return GuiUnattended;
                            }());
                            customization.GuiUnattended = GuiUnattended;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_115.binding || (vim_115.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_116) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption$ConnectOption = (function () {
                                function VirtualDeviceOption$ConnectOption() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$ConnectOption';
                                }
                                return VirtualDeviceOption$ConnectOption;
                            }());
                            device.VirtualDeviceOption$ConnectOption = VirtualDeviceOption$ConnectOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_116.binding || (vim_116.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_117) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var ArrayUpdateSpec = (function () {
                            function ArrayUpdateSpec() {
                                this._type = 'com.vmware.vim.binding.vim.option.ArrayUpdateSpec';
                            }
                            return ArrayUpdateSpec;
                        }());
                        option.ArrayUpdateSpec = ArrayUpdateSpec;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_117.binding || (vim_117.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_118) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigSpec$CpuIdInfoSpec = (function (_super) {
                            __extends(ConfigSpec$CpuIdInfoSpec, _super);
                            function ConfigSpec$CpuIdInfoSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigSpec$CpuIdInfoSpec';
                            }
                            return ConfigSpec$CpuIdInfoSpec;
                        }(com.vmware.vim.binding.vim.option.ArrayUpdateSpec));
                        vm.ConfigSpec$CpuIdInfoSpec = ConfigSpec$CpuIdInfoSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_118.binding || (vim_118.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_119) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var PropertySpec = (function (_super) {
                            __extends(PropertySpec, _super);
                            function PropertySpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vApp.PropertySpec';
                            }
                            return PropertySpec;
                        }(com.vmware.vim.binding.vim.option.ArrayUpdateSpec));
                        vApp.PropertySpec = PropertySpec;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_119.binding || (vim_119.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_120) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var DistributedVirtualSwitch$HostInfrastructureTrafficResource$ResourceAllocation = (function () {
                        function DistributedVirtualSwitch$HostInfrastructureTrafficResource$ResourceAllocation() {
                            this._type = 'com.vmware.vim.binding.vim.DistributedVirtualSwitch$HostInfrastructureTrafficResource$ResourceAllocation';
                        }
                        return DistributedVirtualSwitch$HostInfrastructureTrafficResource$ResourceAllocation;
                    }());
                    vim.DistributedVirtualSwitch$HostInfrastructureTrafficResource$ResourceAllocation = DistributedVirtualSwitch$HostInfrastructureTrafficResource$ResourceAllocation;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_120.binding || (vim_120.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_121) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var LinkDiscoveryProtocolConfig = (function () {
                            function LinkDiscoveryProtocolConfig() {
                                this._type = 'com.vmware.vim.binding.vim.host.LinkDiscoveryProtocolConfig';
                            }
                            return LinkDiscoveryProtocolConfig;
                        }());
                        host.LinkDiscoveryProtocolConfig = LinkDiscoveryProtocolConfig;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_121.binding || (vim_121.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_122) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ElementDescription = (function (_super) {
                        __extends(ElementDescription, _super);
                        function ElementDescription() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.ElementDescription';
                        }
                        return ElementDescription;
                    }(vim.Description));
                    vim.ElementDescription = ElementDescription;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_122.binding || (vim_122.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_123) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var OptionDef = (function (_super) {
                            __extends(OptionDef, _super);
                            function OptionDef() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.option.OptionDef';
                            }
                            return OptionDef;
                        }(com.vmware.vim.binding.vim.ElementDescription));
                        option.OptionDef = OptionDef;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_123.binding || (vim_123.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_124) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ExtendedElementDescription = (function (_super) {
                        __extends(ExtendedElementDescription, _super);
                        function ExtendedElementDescription() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.ExtendedElementDescription';
                        }
                        return ExtendedElementDescription;
                    }(vim.ElementDescription));
                    vim.ExtendedElementDescription = ExtendedElementDescription;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_124.binding || (vim_124.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_125) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var cluster;
                        (function (cluster) {
                            var ConfigInfo = (function () {
                                function ConfigInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.cluster.ConfigInfo';
                                }
                                return ConfigInfo;
                            }());
                            cluster.ConfigInfo = ConfigInfo;
                        })(cluster = vsan.cluster || (vsan.cluster = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_125.binding || (vim_125.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_126) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var Message = (function () {
                            function Message() {
                                this._type = 'com.vmware.vim.binding.vim.vm.Message';
                            }
                            return Message;
                        }());
                        vm.Message = Message;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_126.binding || (vim_126.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_127) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var GuestIntegrityInfo = (function () {
                            function GuestIntegrityInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.GuestIntegrityInfo';
                            }
                            return GuestIntegrityInfo;
                        }());
                        vm.GuestIntegrityInfo = GuestIntegrityInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_127.binding || (vim_127.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_128) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualSwitchInfo = (function () {
                            function DistributedVirtualSwitchInfo() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualSwitchInfo';
                            }
                            return DistributedVirtualSwitchInfo;
                        }());
                        dvs.DistributedVirtualSwitchInfo = DistributedVirtualSwitchInfo;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_128.binding || (vim_128.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_129) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var host;
                        (function (host) {
                            var NasStorageProfile = (function (_super) {
                                __extends(NasStorageProfile, _super);
                                function NasStorageProfile() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.profile.host.NasStorageProfile';
                                }
                                return NasStorageProfile;
                            }(com.vmware.vim.binding.vim.profile.ApplyProfile));
                            host.NasStorageProfile = NasStorageProfile;
                        })(host = profile.host || (profile.host = {}));
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_129.binding || (vim_129.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_130) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var CustomNameGenerator = (function (_super) {
                                __extends(CustomNameGenerator, _super);
                                function CustomNameGenerator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.CustomNameGenerator';
                                }
                                return CustomNameGenerator;
                            }(customization.NameGenerator));
                            customization.CustomNameGenerator = CustomNameGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_130.binding || (vim_130.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_131) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var cluster;
                        (function (cluster) {
                            var ConfigInfo$HostDefaultInfo = (function () {
                                function ConfigInfo$HostDefaultInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.cluster.ConfigInfo$HostDefaultInfo';
                                }
                                return ConfigInfo$HostDefaultInfo;
                            }());
                            cluster.ConfigInfo$HostDefaultInfo = ConfigInfo$HostDefaultInfo;
                        })(cluster = vsan.cluster || (vsan.cluster = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_131.binding || (vim_131.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_132) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var ProductSpec = (function (_super) {
                            __extends(ProductSpec, _super);
                            function ProductSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vApp.ProductSpec';
                            }
                            return ProductSpec;
                        }(com.vmware.vim.binding.vim.option.ArrayUpdateSpec));
                        vApp.ProductSpec = ProductSpec;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_132.binding || (vim_132.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_133) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var UnknownNameGenerator = (function (_super) {
                                __extends(UnknownNameGenerator, _super);
                                function UnknownNameGenerator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.UnknownNameGenerator';
                                }
                                return UnknownNameGenerator;
                            }(customization.NameGenerator));
                            customization.UnknownNameGenerator = UnknownNameGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_133.binding || (vim_133.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_134) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var Datastore$Capability = (function () {
                        function Datastore$Capability() {
                            this._type = 'com.vmware.vim.binding.vim.Datastore$Capability';
                        }
                        return Datastore$Capability;
                    }());
                    vim.Datastore$Capability = Datastore$Capability;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_134.binding || (vim_134.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_135) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DeviceRuntimeInfo$DeviceRuntimeState = (function () {
                            function DeviceRuntimeInfo$DeviceRuntimeState() {
                                this._type = 'com.vmware.vim.binding.vim.vm.DeviceRuntimeInfo$DeviceRuntimeState';
                            }
                            return DeviceRuntimeInfo$DeviceRuntimeState;
                        }());
                        vm.DeviceRuntimeInfo$DeviceRuntimeState = DeviceRuntimeInfo$DeviceRuntimeState;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_135.binding || (vim_135.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_136) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPortOption$URIBackingOption = (function (_super) {
                                __extends(VirtualSerialPortOption$URIBackingOption, _super);
                                function VirtualSerialPortOption$URIBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$URIBackingOption';
                                }
                                return VirtualSerialPortOption$URIBackingOption;
                            }(device.VirtualDeviceOption$URIBackingOption));
                            device.VirtualSerialPortOption$URIBackingOption = VirtualSerialPortOption$URIBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_136.binding || (vim_136.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_137) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$BusSlotInfo = (function () {
                                function VirtualDevice$BusSlotInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$BusSlotInfo';
                                }
                                return VirtualDevice$BusSlotInfo;
                            }());
                            device.VirtualDevice$BusSlotInfo = VirtualDevice$BusSlotInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_137.binding || (vim_137.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_138) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigTarget = (function () {
                            function ConfigTarget() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigTarget';
                            }
                            return ConfigTarget;
                        }());
                        vm.ConfigTarget = ConfigTarget;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_138.binding || (vim_138.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_139) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigInfo$OverheadInfo = (function () {
                            function ConfigInfo$OverheadInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigInfo$OverheadInfo';
                            }
                            return ConfigInfo$OverheadInfo;
                        }());
                        vm.ConfigInfo$OverheadInfo = ConfigInfo$OverheadInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_139.binding || (vim_139.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_140) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var PropertyInfo = (function () {
                            function PropertyInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vApp.PropertyInfo';
                            }
                            return PropertyInfo;
                        }());
                        vApp.PropertyInfo = PropertyInfo;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_140.binding || (vim_140.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_141) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DatastoreOption = (function () {
                            function DatastoreOption() {
                                this._type = 'com.vmware.vim.binding.vim.vm.DatastoreOption';
                            }
                            return DatastoreOption;
                        }());
                        vm.DatastoreOption = DatastoreOption;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_141.binding || (vim_141.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_142) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var AffinityInfo = (function () {
                            function AffinityInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.AffinityInfo';
                            }
                            return AffinityInfo;
                        }());
                        vm.AffinityInfo = AffinityInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_142.binding || (vim_142.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_143) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var InheritablePolicy = (function () {
                        function InheritablePolicy() {
                            this._type = 'com.vmware.vim.binding.vim.InheritablePolicy';
                        }
                        return InheritablePolicy;
                    }());
                    vim.InheritablePolicy = InheritablePolicy;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_143.binding || (vim_143.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_144) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy, _super);
                            function VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy = VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_144.binding || (vim_144.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_145) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$MacManagementPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$MacManagementPolicy, _super);
                            function VmwareDistributedVirtualSwitch$MacManagementPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$MacManagementPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$MacManagementPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$MacManagementPolicy = VmwareDistributedVirtualSwitch$MacManagementPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_145.binding || (vim_145.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_146) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var IntPolicy = (function (_super) {
                        __extends(IntPolicy, _super);
                        function IntPolicy() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.IntPolicy';
                        }
                        return IntPolicy;
                    }(vim.InheritablePolicy));
                    vim.IntPolicy = IntPolicy;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_146.binding || (vim_146.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_147) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$FilterConfig = (function (_super) {
                            __extends(DistributedVirtualPort$FilterConfig, _super);
                            function DistributedVirtualPort$FilterConfig() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$FilterConfig';
                            }
                            return DistributedVirtualPort$FilterConfig;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.DistributedVirtualPort$FilterConfig = DistributedVirtualPort$FilterConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_147.binding || (vim_147.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_148) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$VendorSpecificConfig = (function (_super) {
                            __extends(DistributedVirtualPort$VendorSpecificConfig, _super);
                            function DistributedVirtualPort$VendorSpecificConfig() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$VendorSpecificConfig';
                            }
                            return DistributedVirtualPort$VendorSpecificConfig;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.DistributedVirtualPort$VendorSpecificConfig = DistributedVirtualPort$VendorSpecificConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_148.binding || (vim_148.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_149) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VlanSpec = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$VlanSpec, _super);
                            function VmwareDistributedVirtualSwitch$VlanSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanSpec';
                            }
                            return VmwareDistributedVirtualSwitch$VlanSpec;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$VlanSpec = VmwareDistributedVirtualSwitch$VlanSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_149.binding || (vim_149.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_150) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VlanIdSpec = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$VlanIdSpec, _super);
                            function VmwareDistributedVirtualSwitch$VlanIdSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanIdSpec';
                            }
                            return VmwareDistributedVirtualSwitch$VlanIdSpec;
                        }(dvs.VmwareDistributedVirtualSwitch$VlanSpec));
                        dvs.VmwareDistributedVirtualSwitch$VlanIdSpec = VmwareDistributedVirtualSwitch$VlanIdSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_150.binding || (vim_150.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_151) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$PvlanSpec = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$PvlanSpec, _super);
                            function VmwareDistributedVirtualSwitch$PvlanSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanSpec';
                            }
                            return VmwareDistributedVirtualSwitch$PvlanSpec;
                        }(dvs.VmwareDistributedVirtualSwitch$VlanSpec));
                        dvs.VmwareDistributedVirtualSwitch$PvlanSpec = VmwareDistributedVirtualSwitch$PvlanSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_151.binding || (vim_151.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_152) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$TrunkVlanSpec = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$TrunkVlanSpec, _super);
                            function VmwareDistributedVirtualSwitch$TrunkVlanSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$TrunkVlanSpec';
                            }
                            return VmwareDistributedVirtualSwitch$TrunkVlanSpec;
                        }(dvs.VmwareDistributedVirtualSwitch$VlanSpec));
                        dvs.VmwareDistributedVirtualSwitch$TrunkVlanSpec = VmwareDistributedVirtualSwitch$TrunkVlanSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_152.binding || (vim_152.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_153) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$TrafficFilterConfig = (function (_super) {
                            __extends(DistributedVirtualPort$TrafficFilterConfig, _super);
                            function DistributedVirtualPort$TrafficFilterConfig() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$TrafficFilterConfig';
                            }
                            return DistributedVirtualPort$TrafficFilterConfig;
                        }(dvs.DistributedVirtualPort$FilterConfig));
                        dvs.DistributedVirtualPort$TrafficFilterConfig = DistributedVirtualPort$TrafficFilterConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_153.binding || (vim_153.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_154) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var encryption;
                    (function (encryption) {
                        var KmipServerInfo = (function () {
                            function KmipServerInfo() {
                                this._type = 'com.vmware.vim.binding.vim.encryption.KmipServerInfo';
                            }
                            return KmipServerInfo;
                        }());
                        encryption.KmipServerInfo = KmipServerInfo;
                    })(encryption = vim.encryption || (vim.encryption = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_154.binding || (vim_154.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_155) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DasAdmissionControlPolicy = (function () {
                            function DasAdmissionControlPolicy() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DasAdmissionControlPolicy';
                            }
                            return DasAdmissionControlPolicy;
                        }());
                        cluster.DasAdmissionControlPolicy = DasAdmissionControlPolicy;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_155.binding || (vim_155.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_156) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var VmConfigInfo = (function () {
                            function VmConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vApp.VmConfigInfo';
                            }
                            return VmConfigInfo;
                        }());
                        vApp.VmConfigInfo = VmConfigInfo;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_156.binding || (vim_156.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_157) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var CloneSpec = (function () {
                            function CloneSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.CloneSpec';
                            }
                            return CloneSpec;
                        }());
                        vm.CloneSpec = CloneSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_157.binding || (vim_157.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_158) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption$PipeBackingOption = (function (_super) {
                                __extends(VirtualDeviceOption$PipeBackingOption, _super);
                                function VirtualDeviceOption$PipeBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$PipeBackingOption';
                                }
                                return VirtualDeviceOption$PipeBackingOption;
                            }(device.VirtualDeviceOption$BackingOption));
                            device.VirtualDeviceOption$PipeBackingOption = VirtualDeviceOption$PipeBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_158.binding || (vim_158.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_159) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPortOption$PipeBackingOption = (function (_super) {
                                __extends(VirtualSerialPortOption$PipeBackingOption, _super);
                                function VirtualSerialPortOption$PipeBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$PipeBackingOption';
                                }
                                return VirtualSerialPortOption$PipeBackingOption;
                            }(device.VirtualDeviceOption$PipeBackingOption));
                            device.VirtualSerialPortOption$PipeBackingOption = VirtualSerialPortOption$PipeBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_159.binding || (vim_159.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_160) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var RelocateSpec = (function () {
                            function RelocateSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.RelocateSpec';
                            }
                            return RelocateSpec;
                        }());
                        vm.RelocateSpec = RelocateSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_160.binding || (vim_160.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_161) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var PasswordField = (function () {
                        function PasswordField() {
                            this._type = 'com.vmware.vim.binding.vim.PasswordField';
                        }
                        return PasswordField;
                    }());
                    vim.PasswordField = PasswordField;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_161.binding || (vim_161.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_162) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var PciPassthruConfig = (function () {
                            function PciPassthruConfig() {
                                this._type = 'com.vmware.vim.binding.vim.host.PciPassthruConfig';
                            }
                            return PciPassthruConfig;
                        }());
                        host.PciPassthruConfig = PciPassthruConfig;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_162.binding || (vim_162.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_163) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var SriovConfig = (function (_super) {
                            __extends(SriovConfig, _super);
                            function SriovConfig() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.host.SriovConfig';
                            }
                            return SriovConfig;
                        }(host.PciPassthruConfig));
                        host.SriovConfig = SriovConfig;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_163.binding || (vim_163.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_164) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var PropertyRelation = (function () {
                            function PropertyRelation() {
                                this._type = 'com.vmware.vim.binding.vim.vm.PropertyRelation';
                            }
                            return PropertyRelation;
                        }());
                        vm.PropertyRelation = PropertyRelation;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_164.binding || (vim_164.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_165) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var Specification = (function () {
                                function Specification() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.Specification';
                                }
                                return Specification;
                            }());
                            customization.Specification = Specification;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_165.binding || (vim_165.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_166) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var BootOptions = (function () {
                            function BootOptions() {
                                this._type = 'com.vmware.vim.binding.vim.vm.BootOptions';
                            }
                            return BootOptions;
                        }());
                        vm.BootOptions = BootOptions;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_166.binding || (vim_166.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_167) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var VirtualHardware = (function () {
                            function VirtualHardware() {
                                this._type = 'com.vmware.vim.binding.vim.vm.VirtualHardware';
                            }
                            return VirtualHardware;
                        }());
                        vm.VirtualHardware = VirtualHardware;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_167.binding || (vim_167.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_168) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var DistributedVirtualSwitch$HealthCheckConfig = (function () {
                        function DistributedVirtualSwitch$HealthCheckConfig() {
                            this._type = 'com.vmware.vim.binding.vim.DistributedVirtualSwitch$HealthCheckConfig';
                        }
                        return DistributedVirtualSwitch$HealthCheckConfig;
                    }());
                    vim.DistributedVirtualSwitch$HealthCheckConfig = DistributedVirtualSwitch$HealthCheckConfig;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_168.binding || (vim_168.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_169) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var FixedName = (function (_super) {
                                __extends(FixedName, _super);
                                function FixedName() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.FixedName';
                                }
                                return FixedName;
                            }(customization.NameGenerator));
                            customization.FixedName = FixedName;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_169.binding || (vim_169.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_170) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var GuestOsDescriptor = (function () {
                            function GuestOsDescriptor() {
                                this._type = 'com.vmware.vim.binding.vim.vm.GuestOsDescriptor';
                            }
                            return GuestOsDescriptor;
                        }());
                        vm.GuestOsDescriptor = GuestOsDescriptor;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_170.binding || (vim_170.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_171) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var Summary$ConfigSummary = (function () {
                            function Summary$ConfigSummary() {
                                this._type = 'com.vmware.vim.binding.vim.vm.Summary$ConfigSummary';
                            }
                            return Summary$ConfigSummary;
                        }());
                        vm.Summary$ConfigSummary = Summary$ConfigSummary;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_171.binding || (vim_171.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_172) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$LagIpfixConfig = (function () {
                            function VmwareDistributedVirtualSwitch$LagIpfixConfig() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LagIpfixConfig';
                            }
                            return VmwareDistributedVirtualSwitch$LagIpfixConfig;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$LagIpfixConfig = VmwareDistributedVirtualSwitch$LagIpfixConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_172.binding || (vim_172.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_173) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigInfo = (function () {
                            function ConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigInfo';
                            }
                            return ConfigInfo;
                        }());
                        vm.ConfigInfo = ConfigInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_173.binding || (vim_173.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_174) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$ConfigSpec = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$ConfigSpec, _super);
                            function VmwareDistributedVirtualSwitch$ConfigSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec';
                            }
                            return VmwareDistributedVirtualSwitch$ConfigSpec;
                        }(com.vmware.vim.binding.vim.DistributedVirtualSwitch$ConfigSpec));
                        dvs.VmwareDistributedVirtualSwitch$ConfigSpec = VmwareDistributedVirtualSwitch$ConfigSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_174.binding || (vim_174.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_175) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var KeyValue = (function () {
                        function KeyValue() {
                            this._type = 'com.vmware.vim.binding.vim.KeyValue';
                        }
                        return KeyValue;
                    }());
                    vim.KeyValue = KeyValue;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_175.binding || (vim_175.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_176) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var Device = (function () {
                            function Device() {
                                this._type = 'com.vmware.vim.binding.vim.host.Device';
                            }
                            return Device;
                        }());
                        host.Device = Device;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_176.binding || (vim_176.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_177) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$FilterPolicy = (function (_super) {
                            __extends(DistributedVirtualPort$FilterPolicy, _super);
                            function DistributedVirtualPort$FilterPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$FilterPolicy';
                            }
                            return DistributedVirtualPort$FilterPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.DistributedVirtualPort$FilterPolicy = DistributedVirtualPort$FilterPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_177.binding || (vim_177.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_178) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var storageDrs;
                    (function (storageDrs) {
                        var PodSelectionSpec$DiskLocator = (function () {
                            function PodSelectionSpec$DiskLocator() {
                                this._type = 'com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec$DiskLocator';
                            }
                            return PodSelectionSpec$DiskLocator;
                        }());
                        storageDrs.PodSelectionSpec$DiskLocator = PodSelectionSpec$DiskLocator;
                    })(storageDrs = vim.storageDrs || (vim.storageDrs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_178.binding || (vim_178.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_179) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DrsVmConfigInfo = (function () {
                            function DrsVmConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DrsVmConfigInfo';
                            }
                            return DrsVmConfigInfo;
                        }());
                        cluster.DrsVmConfigInfo = DrsVmConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_179.binding || (vim_179.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_180) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var ProductInfo = (function () {
                            function ProductInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vApp.ProductInfo';
                            }
                            return ProductInfo;
                        }());
                        vApp.ProductInfo = ProductInfo;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_180.binding || (vim_180.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_181) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var DistributedVirtualSwitch$SwitchPolicy = (function () {
                        function DistributedVirtualSwitch$SwitchPolicy() {
                            this._type = 'com.vmware.vim.binding.vim.DistributedVirtualSwitch$SwitchPolicy';
                        }
                        return DistributedVirtualSwitch$SwitchPolicy;
                    }());
                    vim.DistributedVirtualSwitch$SwitchPolicy = DistributedVirtualSwitch$SwitchPolicy;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_181.binding || (vim_181.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_182) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var GroupInfo = (function () {
                            function GroupInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.GroupInfo';
                            }
                            return GroupInfo;
                        }());
                        cluster.GroupInfo = GroupInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_182.binding || (vim_182.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_183) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var IpConfig = (function () {
                            function IpConfig() {
                                this._type = 'com.vmware.vim.binding.vim.host.IpConfig';
                            }
                            return IpConfig;
                        }());
                        host.IpConfig = IpConfig;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_183.binding || (vim_183.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_184) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ConfigInfo$DatastoreUrlPair = (function () {
                            function ConfigInfo$DatastoreUrlPair() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ConfigInfo$DatastoreUrlPair';
                            }
                            return ConfigInfo$DatastoreUrlPair;
                        }());
                        vm.ConfigInfo$DatastoreUrlPair = ConfigInfo$DatastoreUrlPair;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_184.binding || (vim_184.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_185) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPortgroup$ConfigSpec = (function () {
                            function DistributedVirtualPortgroup$ConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec';
                            }
                            return DistributedVirtualPortgroup$ConfigSpec;
                        }());
                        dvs.DistributedVirtualPortgroup$ConfigSpec = DistributedVirtualPortgroup$ConfigSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_185.binding || (vim_185.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var profile;
                    (function (profile) {
                        var CapabilityBasedProfile = (function (_super) {
                            __extends(CapabilityBasedProfile, _super);
                            function CapabilityBasedProfile() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.pbm.profile.CapabilityBasedProfile';
                            }
                            return CapabilityBasedProfile;
                        }(profile.Profile));
                        profile.CapabilityBasedProfile = CapabilityBasedProfile;
                    })(profile = pbm.profile || (pbm.profile = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_186) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVideoCardOption = (function (_super) {
                                __extends(VirtualVideoCardOption, _super);
                                function VirtualVideoCardOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVideoCardOption';
                                }
                                return VirtualVideoCardOption;
                            }(device.VirtualDeviceOption));
                            device.VirtualVideoCardOption = VirtualVideoCardOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_186.binding || (vim_186.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_187) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var Summary$QuickStats = (function () {
                            function Summary$QuickStats() {
                                this._type = 'com.vmware.vim.binding.vim.vm.Summary$QuickStats';
                            }
                            return Summary$QuickStats;
                        }());
                        vm.Summary$QuickStats = Summary$QuickStats;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_187.binding || (vim_187.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_188) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var IpConfig$IpV6AddressConfiguration = (function () {
                            function IpConfig$IpV6AddressConfiguration() {
                                this._type = 'com.vmware.vim.binding.vim.host.IpConfig$IpV6AddressConfiguration';
                            }
                            return IpConfig$IpV6AddressConfiguration;
                        }());
                        host.IpConfig$IpV6AddressConfiguration = IpConfig$IpV6AddressConfiguration;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_188.binding || (vim_188.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_189) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var VmComponentProtectionSettings = (function () {
                            function VmComponentProtectionSettings() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.VmComponentProtectionSettings';
                            }
                            return VmComponentProtectionSettings;
                        }());
                        cluster.VmComponentProtectionSettings = VmComponentProtectionSettings;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_189.binding || (vim_189.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_190) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DrsFaults$FaultsByVm = (function () {
                            function DrsFaults$FaultsByVm() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DrsFaults$FaultsByVm';
                            }
                            return DrsFaults$FaultsByVm;
                        }());
                        cluster.DrsFaults$FaultsByVm = DrsFaults$FaultsByVm;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_190.binding || (vim_190.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_191) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy, _super);
                            function VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy = VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_191.binding || (vim_191.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_192) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig, _super);
                            function VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig';
                            }
                            return VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig;
                        }(com.vmware.vim.binding.vim.DistributedVirtualSwitch$HealthCheckConfig));
                        dvs.VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig = VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_192.binding || (vim_192.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_193) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig, _super);
                            function VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig';
                            }
                            return VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig;
                        }(dvs.VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig));
                        dvs.VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig = VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_193.binding || (vim_193.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_194) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var OrchestrationInfo = (function () {
                            function OrchestrationInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.OrchestrationInfo';
                            }
                            return OrchestrationInfo;
                        }());
                        cluster.OrchestrationInfo = OrchestrationInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_194.binding || (vim_194.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_195) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var VmOrchestrationInfo = (function () {
                            function VmOrchestrationInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.VmOrchestrationInfo';
                            }
                            return VmOrchestrationInfo;
                        }());
                        cluster.VmOrchestrationInfo = VmOrchestrationInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_195.binding || (vim_195.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_196) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var ConfigInfo$StorageInfo = (function () {
                                function ConfigInfo$StorageInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.ConfigInfo$StorageInfo';
                                }
                                return ConfigInfo$StorageInfo;
                            }());
                            host.ConfigInfo$StorageInfo = ConfigInfo$StorageInfo;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_196.binding || (vim_196.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_197) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var BoolPolicy = (function (_super) {
                        __extends(BoolPolicy, _super);
                        function BoolPolicy() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.BoolPolicy';
                        }
                        return BoolPolicy;
                    }(vim.InheritablePolicy));
                    vim.BoolPolicy = BoolPolicy;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_197.binding || (vim_197.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_198) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPortgroupInfo = (function () {
                            function DistributedVirtualPortgroupInfo() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroupInfo';
                            }
                            return DistributedVirtualPortgroupInfo;
                        }());
                        dvs.DistributedVirtualPortgroupInfo = DistributedVirtualPortgroupInfo;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_198.binding || (vim_198.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_199) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$IscsiIpv6Address = (function () {
                            function InternetScsiHba$IscsiIpv6Address() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$IscsiIpv6Address';
                            }
                            return InternetScsiHba$IscsiIpv6Address;
                        }());
                        host.InternetScsiHba$IscsiIpv6Address = InternetScsiHba$IscsiIpv6Address;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_199.binding || (vim_199.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_200) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DeviceRuntimeInfo = (function () {
                            function DeviceRuntimeInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.DeviceRuntimeInfo';
                            }
                            return DeviceRuntimeInfo;
                        }());
                        vm.DeviceRuntimeInfo = DeviceRuntimeInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_200.binding || (vim_200.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_201) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$TrafficFilterConfigSpec = (function (_super) {
                            __extends(DistributedVirtualPort$TrafficFilterConfigSpec, _super);
                            function DistributedVirtualPort$TrafficFilterConfigSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$TrafficFilterConfigSpec';
                            }
                            return DistributedVirtualPort$TrafficFilterConfigSpec;
                        }(dvs.DistributedVirtualPort$TrafficFilterConfig));
                        dvs.DistributedVirtualPort$TrafficFilterConfigSpec = DistributedVirtualPort$TrafficFilterConfigSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_201.binding || (vim_201.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_202) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var QuestionInfo = (function () {
                            function QuestionInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.QuestionInfo';
                            }
                            return QuestionInfo;
                        }());
                        vm.QuestionInfo = QuestionInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_202.binding || (vim_202.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_203) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var OvfSectionInfo = (function () {
                            function OvfSectionInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vApp.OvfSectionInfo';
                            }
                            return OvfSectionInfo;
                        }());
                        vApp.OvfSectionInfo = OvfSectionInfo;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_203.binding || (vim_203.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_204) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIControllerOption = (function (_super) {
                                __extends(VirtualPCIControllerOption, _super);
                                function VirtualPCIControllerOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIControllerOption';
                                }
                                return VirtualPCIControllerOption;
                            }(device.VirtualControllerOption));
                            device.VirtualPCIControllerOption = VirtualPCIControllerOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_204.binding || (vim_204.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_205) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ResourcePool$ResourceUsage = (function () {
                        function ResourcePool$ResourceUsage() {
                            this._type = 'com.vmware.vim.binding.vim.ResourcePool$ResourceUsage';
                        }
                        return ResourcePool$ResourceUsage;
                    }());
                    vim.ResourcePool$ResourceUsage = ResourcePool$ResourceUsage;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_205.binding || (vim_205.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_206) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthroughOption$PluginBackingOption = (function (_super) {
                                __extends(VirtualPCIPassthroughOption$PluginBackingOption, _super);
                                function VirtualPCIPassthroughOption$PluginBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption$PluginBackingOption';
                                }
                                return VirtualPCIPassthroughOption$PluginBackingOption;
                            }(device.VirtualDeviceOption$BackingOption));
                            device.VirtualPCIPassthroughOption$PluginBackingOption = VirtualPCIPassthroughOption$PluginBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_206.binding || (vim_206.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_207) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthroughOption$VmiopBackingOption = (function (_super) {
                                __extends(VirtualPCIPassthroughOption$VmiopBackingOption, _super);
                                function VirtualPCIPassthroughOption$VmiopBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption$VmiopBackingOption';
                                }
                                return VirtualPCIPassthroughOption$VmiopBackingOption;
                            }(device.VirtualPCIPassthroughOption$PluginBackingOption));
                            device.VirtualPCIPassthroughOption$VmiopBackingOption = VirtualPCIPassthroughOption$VmiopBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_207.binding || (vim_207.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_208) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var storageDrs;
                    (function (storageDrs) {
                        var VmConfigInfo = (function () {
                            function VmConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.storageDrs.VmConfigInfo';
                            }
                            return VmConfigInfo;
                        }());
                        storageDrs.VmConfigInfo = VmConfigInfo;
                    })(storageDrs = vim.storageDrs || (vim.storageDrs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_208.binding || (vim_208.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_209) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule = (function () {
                            function TrafficRule() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule';
                            }
                            return TrafficRule;
                        }());
                        dvs.TrafficRule = TrafficRule;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_209.binding || (vim_209.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_210) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var Summary$GuestSummary = (function () {
                            function Summary$GuestSummary() {
                                this._type = 'com.vmware.vim.binding.vim.vm.Summary$GuestSummary';
                            }
                            return Summary$GuestSummary;
                        }());
                        vm.Summary$GuestSummary = Summary$GuestSummary;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_210.binding || (vim_210.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_211) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$FilterParameter = (function () {
                            function DistributedVirtualPort$FilterParameter() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$FilterParameter';
                            }
                            return DistributedVirtualPort$FilterParameter;
                        }());
                        dvs.DistributedVirtualPort$FilterParameter = DistributedVirtualPort$FilterParameter;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_211.binding || (vim_211.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_212) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy, _super);
                            function VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy;
                        }(dvs.DistributedVirtualPortgroup$PortgroupPolicy));
                        dvs.VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy = VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_212.binding || (vim_212.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_213) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var CompositePolicyOption = (function (_super) {
                            __extends(CompositePolicyOption, _super);
                            function CompositePolicyOption() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.profile.CompositePolicyOption';
                            }
                            return CompositePolicyOption;
                        }(profile.PolicyOption));
                        profile.CompositePolicyOption = CompositePolicyOption;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_213.binding || (vim_213.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var vmodl;
                (function (vmodl) {
                    var ManagedObjectReference = (function () {
                        function ManagedObjectReference() {
                            this._type = 'com.vmware.vim.binding.vmodl.ManagedObjectReference';
                        }
                        return ManagedObjectReference;
                    }());
                    vmodl.ManagedObjectReference = ManagedObjectReference;
                })(vmodl = binding.vmodl || (binding.vmodl = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_214) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$TargetSet = (function () {
                            function InternetScsiHba$TargetSet() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$TargetSet';
                            }
                            return InternetScsiHba$TargetSet;
                        }());
                        host.InternetScsiHba$TargetSet = InternetScsiHba$TargetSet;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_214.binding || (vim_214.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_215) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DrsFaults = (function () {
                            function DrsFaults() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DrsFaults';
                            }
                            return DrsFaults;
                        }());
                        cluster.DrsFaults = DrsFaults;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_215.binding || (vim_215.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_216) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$PvlanMapEntry = (function () {
                            function VmwareDistributedVirtualSwitch$PvlanMapEntry() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry';
                            }
                            return VmwareDistributedVirtualSwitch$PvlanMapEntry;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry = VmwareDistributedVirtualSwitch$PvlanMapEntry;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_216.binding || (vim_216.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_217) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualUSBOption = (function (_super) {
                                __extends(VirtualUSBOption, _super);
                                function VirtualUSBOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualUSBOption';
                                }
                                return VirtualUSBOption;
                            }(device.VirtualDeviceOption));
                            device.VirtualUSBOption = VirtualUSBOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_217.binding || (vim_217.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_218) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var DiskMapping = (function () {
                                function DiskMapping() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.DiskMapping';
                                }
                                return DiskMapping;
                            }());
                            host.DiskMapping = DiskMapping;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_218.binding || (vim_218.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_219) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var Action = (function () {
                            function Action() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.Action';
                            }
                            return Action;
                        }());
                        cluster.Action = Action;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_219.binding || (vim_219.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_220) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var VmfsVolume = (function (_super) {
                            __extends(VmfsVolume, _super);
                            function VmfsVolume() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.host.VmfsVolume';
                            }
                            return VmfsVolume;
                        }(host.FileSystemVolume));
                        host.VmfsVolume = VmfsVolume;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_220.binding || (vim_220.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_221) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ServiceLocator$Credential = (function () {
                        function ServiceLocator$Credential() {
                            this._type = 'com.vmware.vim.binding.vim.ServiceLocator$Credential';
                        }
                        return ServiceLocator$Credential;
                    }());
                    vim.ServiceLocator$Credential = ServiceLocator$Credential;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_221.binding || (vim_221.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_222) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VspanSession = (function () {
                            function VmwareDistributedVirtualSwitch$VspanSession() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VspanSession';
                            }
                            return VmwareDistributedVirtualSwitch$VspanSession;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$VspanSession = VmwareDistributedVirtualSwitch$VspanSession;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_222.binding || (vim_222.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_223) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var SharesOption = (function () {
                        function SharesOption() {
                            this._type = 'com.vmware.vim.binding.vim.SharesOption';
                        }
                        return SharesOption;
                    }());
                    vim.SharesOption = SharesOption;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_223.binding || (vim_223.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_224) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$StaticTarget = (function () {
                            function InternetScsiHba$StaticTarget() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$StaticTarget';
                            }
                            return InternetScsiHba$StaticTarget;
                        }());
                        host.InternetScsiHba$StaticTarget = InternetScsiHba$StaticTarget;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_224.binding || (vim_224.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_225) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$ParamValue = (function (_super) {
                            __extends(InternetScsiHba$ParamValue, _super);
                            function InternetScsiHba$ParamValue() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$ParamValue';
                            }
                            return InternetScsiHba$ParamValue;
                        }(com.vmware.vim.binding.vim.option.OptionValue));
                        host.InternetScsiHba$ParamValue = InternetScsiHba$ParamValue;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_225.binding || (vim_225.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_226) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FaultToleranceMetaSpec = (function () {
                            function FaultToleranceMetaSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FaultToleranceMetaSpec';
                            }
                            return FaultToleranceMetaSpec;
                        }());
                        vm.FaultToleranceMetaSpec = FaultToleranceMetaSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_226.binding || (vim_226.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var profile;
                    (function (profile) {
                        var ProfileId = (function () {
                            function ProfileId() {
                                this._type = 'com.vmware.vim.binding.pbm.profile.ProfileId';
                            }
                            return ProfileId;
                        }());
                        profile.ProfileId = ProfileId;
                    })(profile = pbm.profile || (pbm.profile = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_227) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var RelocateSpec$DiskLocator$BackingSpec = (function () {
                            function RelocateSpec$DiskLocator$BackingSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator$BackingSpec';
                            }
                            return RelocateSpec$DiskLocator$BackingSpec;
                        }());
                        vm.RelocateSpec$DiskLocator$BackingSpec = RelocateSpec$DiskLocator$BackingSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_227.binding || (vim_227.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_228) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var TargetInfo = (function () {
                            function TargetInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.TargetInfo';
                            }
                            return TargetInfo;
                        }());
                        vm.TargetInfo = TargetInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_228.binding || (vim_228.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_229) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DatastoreInfo = (function (_super) {
                            __extends(DatastoreInfo, _super);
                            function DatastoreInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.DatastoreInfo';
                            }
                            return DatastoreInfo;
                        }(vm.TargetInfo));
                        vm.DatastoreInfo = DatastoreInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_229.binding || (vim_229.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_230) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var PciSharedGpuPassthroughInfo = (function (_super) {
                            __extends(PciSharedGpuPassthroughInfo, _super);
                            function PciSharedGpuPassthroughInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.PciSharedGpuPassthroughInfo';
                            }
                            return PciSharedGpuPassthroughInfo;
                        }(vm.TargetInfo));
                        vm.PciSharedGpuPassthroughInfo = PciSharedGpuPassthroughInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_230.binding || (vim_230.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_231) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DiskDeviceInfo = (function (_super) {
                            __extends(DiskDeviceInfo, _super);
                            function DiskDeviceInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.DiskDeviceInfo';
                            }
                            return DiskDeviceInfo;
                        }(vm.TargetInfo));
                        vm.DiskDeviceInfo = DiskDeviceInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_231.binding || (vim_231.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_232) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var SerialInfo = (function (_super) {
                            __extends(SerialInfo, _super);
                            function SerialInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.SerialInfo';
                            }
                            return SerialInfo;
                        }(vm.TargetInfo));
                        vm.SerialInfo = SerialInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_232.binding || (vim_232.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_233) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var UsbInfo = (function (_super) {
                            __extends(UsbInfo, _super);
                            function UsbInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.UsbInfo';
                            }
                            return UsbInfo;
                        }(vm.TargetInfo));
                        vm.UsbInfo = UsbInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_233.binding || (vim_233.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_234) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var NetworkInfo = (function (_super) {
                            __extends(NetworkInfo, _super);
                            function NetworkInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.NetworkInfo';
                            }
                            return NetworkInfo;
                        }(vm.TargetInfo));
                        vm.NetworkInfo = NetworkInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_234.binding || (vim_234.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_235) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var IdeDiskDeviceInfo = (function (_super) {
                            __extends(IdeDiskDeviceInfo, _super);
                            function IdeDiskDeviceInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.IdeDiskDeviceInfo';
                            }
                            return IdeDiskDeviceInfo;
                        }(vm.DiskDeviceInfo));
                        vm.IdeDiskDeviceInfo = IdeDiskDeviceInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_235.binding || (vim_235.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_236) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var VFlashModuleInfo = (function (_super) {
                            __extends(VFlashModuleInfo, _super);
                            function VFlashModuleInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.VFlashModuleInfo';
                            }
                            return VFlashModuleInfo;
                        }(vm.TargetInfo));
                        vm.VFlashModuleInfo = VFlashModuleInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_236.binding || (vim_236.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_237) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var OpaqueNetworkInfo = (function (_super) {
                            __extends(OpaqueNetworkInfo, _super);
                            function OpaqueNetworkInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.OpaqueNetworkInfo';
                            }
                            return OpaqueNetworkInfo;
                        }(vm.TargetInfo));
                        vm.OpaqueNetworkInfo = OpaqueNetworkInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_237.binding || (vim_237.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_238) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var CdromInfo = (function (_super) {
                            __extends(CdromInfo, _super);
                            function CdromInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.CdromInfo';
                            }
                            return CdromInfo;
                        }(vm.TargetInfo));
                        vm.CdromInfo = CdromInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_238.binding || (vim_238.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_239) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var SoundInfo = (function (_super) {
                            __extends(SoundInfo, _super);
                            function SoundInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.SoundInfo';
                            }
                            return SoundInfo;
                        }(vm.TargetInfo));
                        vm.SoundInfo = SoundInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_239.binding || (vim_239.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_240) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ParallelInfo = (function (_super) {
                            __extends(ParallelInfo, _super);
                            function ParallelInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.ParallelInfo';
                            }
                            return ParallelInfo;
                        }(vm.TargetInfo));
                        vm.ParallelInfo = ParallelInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_240.binding || (vim_240.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_241) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var ProactiveDrsConfigInfo = (function () {
                            function ProactiveDrsConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.ProactiveDrsConfigInfo';
                            }
                            return ProactiveDrsConfigInfo;
                        }());
                        cluster.ProactiveDrsConfigInfo = ProactiveDrsConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_241.binding || (vim_241.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_242) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var LongPolicy = (function (_super) {
                        __extends(LongPolicy, _super);
                        function LongPolicy() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.LongPolicy';
                        }
                        return LongPolicy;
                    }(vim.InheritablePolicy));
                    vim.LongPolicy = LongPolicy;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_242.binding || (vim_242.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_243) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ProfileSpec = (function () {
                            function ProfileSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ProfileSpec';
                            }
                            return ProfileSpec;
                        }());
                        vm.ProfileSpec = ProfileSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_243.binding || (vim_243.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_244) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var DefinedProfileSpec = (function (_super) {
                            __extends(DefinedProfileSpec, _super);
                            function DefinedProfileSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.DefinedProfileSpec';
                            }
                            return DefinedProfileSpec;
                        }(vm.ProfileSpec));
                        vm.DefinedProfileSpec = DefinedProfileSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_244.binding || (vim_244.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_245) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$IPv6Properties = (function () {
                            function InternetScsiHba$IPv6Properties() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$IPv6Properties';
                            }
                            return InternetScsiHba$IPv6Properties;
                        }());
                        host.InternetScsiHba$IPv6Properties = InternetScsiHba$IPv6Properties;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_245.binding || (vim_245.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_246) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var OptionType = (function () {
                            function OptionType() {
                                this._type = 'com.vmware.vim.binding.vim.option.OptionType';
                            }
                            return OptionType;
                        }());
                        option.OptionType = OptionType;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_246.binding || (vim_246.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_247) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var ChoiceOption = (function (_super) {
                            __extends(ChoiceOption, _super);
                            function ChoiceOption() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.option.ChoiceOption';
                            }
                            return ChoiceOption;
                        }(option.OptionType));
                        option.ChoiceOption = ChoiceOption;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_247.binding || (vim_247.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_248) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var LongOption = (function (_super) {
                            __extends(LongOption, _super);
                            function LongOption() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.option.LongOption';
                            }
                            return LongOption;
                        }(option.OptionType));
                        option.LongOption = LongOption;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_248.binding || (vim_248.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_249) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var BoolOption = (function (_super) {
                            __extends(BoolOption, _super);
                            function BoolOption() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.option.BoolOption';
                            }
                            return BoolOption;
                        }(option.OptionType));
                        option.BoolOption = BoolOption;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_249.binding || (vim_249.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_250) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var StringOption = (function (_super) {
                            __extends(StringOption, _super);
                            function StringOption() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.option.StringOption';
                            }
                            return StringOption;
                        }(option.OptionType));
                        option.StringOption = StringOption;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_250.binding || (vim_250.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_251) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$BackingInfo = (function () {
                                function VirtualDevice$BackingInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$BackingInfo';
                                }
                                return VirtualDevice$BackingInfo;
                            }());
                            device.VirtualDevice$BackingInfo = VirtualDevice$BackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_251.binding || (vim_251.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_252) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPort$ThinPrintBackingInfo = (function (_super) {
                                __extends(VirtualSerialPort$ThinPrintBackingInfo, _super);
                                function VirtualSerialPort$ThinPrintBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$ThinPrintBackingInfo';
                                }
                                return VirtualSerialPort$ThinPrintBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualSerialPort$ThinPrintBackingInfo = VirtualSerialPort$ThinPrintBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_252.binding || (vim_252.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_253) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSriovEthernetCard$SriovBackingInfo = (function (_super) {
                                __extends(VirtualSriovEthernetCard$SriovBackingInfo, _super);
                                function VirtualSriovEthernetCard$SriovBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCard$SriovBackingInfo';
                                }
                                return VirtualSriovEthernetCard$SriovBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualSriovEthernetCard$SriovBackingInfo = VirtualSriovEthernetCard$SriovBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_253.binding || (vim_253.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_254) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$PipeBackingInfo = (function (_super) {
                                __extends(VirtualDevice$PipeBackingInfo, _super);
                                function VirtualDevice$PipeBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$PipeBackingInfo';
                                }
                                return VirtualDevice$PipeBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualDevice$PipeBackingInfo = VirtualDevice$PipeBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_254.binding || (vim_254.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_255) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthrough$PluginBackingInfo = (function (_super) {
                                __extends(VirtualPCIPassthrough$PluginBackingInfo, _super);
                                function VirtualPCIPassthrough$PluginBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$PluginBackingInfo';
                                }
                                return VirtualPCIPassthrough$PluginBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualPCIPassthrough$PluginBackingInfo = VirtualPCIPassthrough$PluginBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_255.binding || (vim_255.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_256) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualEthernetCard$DistributedVirtualPortBackingInfo = (function (_super) {
                                __extends(VirtualEthernetCard$DistributedVirtualPortBackingInfo, _super);
                                function VirtualEthernetCard$DistributedVirtualPortBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$DistributedVirtualPortBackingInfo';
                                }
                                return VirtualEthernetCard$DistributedVirtualPortBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualEthernetCard$DistributedVirtualPortBackingInfo = VirtualEthernetCard$DistributedVirtualPortBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_256.binding || (vim_256.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_257) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualEthernetCard$OpaqueNetworkBackingInfo = (function (_super) {
                                __extends(VirtualEthernetCard$OpaqueNetworkBackingInfo, _super);
                                function VirtualEthernetCard$OpaqueNetworkBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$OpaqueNetworkBackingInfo';
                                }
                                return VirtualEthernetCard$OpaqueNetworkBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualEthernetCard$OpaqueNetworkBackingInfo = VirtualEthernetCard$OpaqueNetworkBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_257.binding || (vim_257.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_258) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthrough$VmiopBackingInfo = (function (_super) {
                                __extends(VirtualPCIPassthrough$VmiopBackingInfo, _super);
                                function VirtualPCIPassthrough$VmiopBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$VmiopBackingInfo';
                                }
                                return VirtualPCIPassthrough$VmiopBackingInfo;
                            }(device.VirtualPCIPassthrough$PluginBackingInfo));
                            device.VirtualPCIPassthrough$VmiopBackingInfo = VirtualPCIPassthrough$VmiopBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_258.binding || (vim_258.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_259) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DpmHostConfigInfo = (function () {
                            function DpmHostConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DpmHostConfigInfo';
                            }
                            return DpmHostConfigInfo;
                        }());
                        cluster.DpmHostConfigInfo = DpmHostConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_259.binding || (vim_259.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_260) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var BootOptions$BootableDevice = (function () {
                            function BootOptions$BootableDevice() {
                                this._type = 'com.vmware.vim.binding.vim.vm.BootOptions$BootableDevice';
                            }
                            return BootOptions$BootableDevice;
                        }());
                        vm.BootOptions$BootableDevice = BootOptions$BootableDevice;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_260.binding || (vim_260.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var profile;
                    (function (profile) {
                        var ResourceType = (function () {
                            function ResourceType() {
                                this._type = 'com.vmware.vim.binding.pbm.profile.ResourceType';
                            }
                            return ResourceType;
                        }());
                        profile.ResourceType = ResourceType;
                    })(profile = pbm.profile || (pbm.profile = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_261) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$SystemTrafficQualifier = (function (_super) {
                            __extends(TrafficRule$SystemTrafficQualifier, _super);
                            function TrafficRule$SystemTrafficQualifier() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$SystemTrafficQualifier';
                            }
                            return TrafficRule$SystemTrafficQualifier;
                        }(dvs.TrafficRule$Qualifier));
                        dvs.TrafficRule$SystemTrafficQualifier = TrafficRule$SystemTrafficQualifier;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_261.binding || (vim_261.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_262) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var OvfSectionSpec = (function (_super) {
                            __extends(OvfSectionSpec, _super);
                            function OvfSectionSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vApp.OvfSectionSpec';
                            }
                            return OvfSectionSpec;
                        }(com.vmware.vim.binding.vim.option.ArrayUpdateSpec));
                        vApp.OvfSectionSpec = OvfSectionSpec;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_262.binding || (vim_262.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_263) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ScheduledHardwareUpgradeInfo = (function () {
                            function ScheduledHardwareUpgradeInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ScheduledHardwareUpgradeInfo';
                            }
                            return ScheduledHardwareUpgradeInfo;
                        }());
                        vm.ScheduledHardwareUpgradeInfo = ScheduledHardwareUpgradeInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_263.binding || (vim_263.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_264) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var PolicyOptionMetadata = (function () {
                            function PolicyOptionMetadata() {
                                this._type = 'com.vmware.vim.binding.vim.profile.PolicyOptionMetadata';
                            }
                            return PolicyOptionMetadata;
                        }());
                        profile.PolicyOptionMetadata = PolicyOptionMetadata;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_264.binding || (vim_264.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_265) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var UserInputRequiredParameterMetadata = (function (_super) {
                            __extends(UserInputRequiredParameterMetadata, _super);
                            function UserInputRequiredParameterMetadata() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.profile.UserInputRequiredParameterMetadata';
                            }
                            return UserInputRequiredParameterMetadata;
                        }(profile.PolicyOptionMetadata));
                        profile.UserInputRequiredParameterMetadata = UserInputRequiredParameterMetadata;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_265.binding || (vim_265.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_266) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var option;
                    (function (option) {
                        var IntOption = (function (_super) {
                            __extends(IntOption, _super);
                            function IntOption() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.option.IntOption';
                            }
                            return IntOption;
                        }(option.OptionType));
                        option.IntOption = IntOption;
                    })(option = vim.option || (vim.option = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_266.binding || (vim_266.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_267) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDisk$VFlashCacheConfigInfo = (function () {
                                function VirtualDisk$VFlashCacheConfigInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDisk$VFlashCacheConfigInfo';
                                }
                                return VirtualDisk$VFlashCacheConfigInfo;
                            }());
                            device.VirtualDisk$VFlashCacheConfigInfo = VirtualDisk$VFlashCacheConfigInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_267.binding || (vim_267.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_268) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$AcceptAction = (function (_super) {
                            __extends(TrafficRule$AcceptAction, _super);
                            function TrafficRule$AcceptAction() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$AcceptAction';
                            }
                            return TrafficRule$AcceptAction;
                        }(dvs.TrafficRule$Action));
                        dvs.TrafficRule$AcceptAction = TrafficRule$AcceptAction;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_268.binding || (vim_268.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_269) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$DigestProperties = (function () {
                            function InternetScsiHba$DigestProperties() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$DigestProperties';
                            }
                            return InternetScsiHba$DigestProperties;
                        }());
                        host.InternetScsiHba$DigestProperties = InternetScsiHba$DigestProperties;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_269.binding || (vim_269.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_270) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var ConfigInfo = (function () {
                                function ConfigInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.ConfigInfo';
                                }
                                return ConfigInfo;
                            }());
                            host.ConfigInfo = ConfigInfo;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_270.binding || (vim_270.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_271) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var InternetScsiHba$IPProperties = (function () {
                            function InternetScsiHba$IPProperties() {
                                this._type = 'com.vmware.vim.binding.vim.host.InternetScsiHba$IPProperties';
                            }
                            return InternetScsiHba$IPProperties;
                        }());
                        host.InternetScsiHba$IPProperties = InternetScsiHba$IPProperties;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_271.binding || (vim_271.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_272) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var replication;
                        (function (replication) {
                            var FaultDomainId = (function () {
                                function FaultDomainId() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.replication.FaultDomainId';
                                }
                                return FaultDomainId;
                            }());
                            replication.FaultDomainId = FaultDomainId;
                        })(replication = vm.replication || (vm.replication = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_272.binding || (vim_272.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_273) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vslm;
                    (function (vslm) {
                        var ID = (function () {
                            function ID() {
                                this._type = 'com.vmware.vim.binding.vim.vslm.ID';
                            }
                            return ID;
                        }());
                        vslm.ID = ID;
                    })(vslm = vim.vslm || (vim.vslm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_273.binding || (vim_273.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_274) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ScsiPassthroughInfo = (function (_super) {
                            __extends(ScsiPassthroughInfo, _super);
                            function ScsiPassthroughInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.ScsiPassthroughInfo';
                            }
                            return ScsiPassthroughInfo;
                        }(vm.TargetInfo));
                        vm.ScsiPassthroughInfo = ScsiPassthroughInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_274.binding || (vim_274.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_275) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var Identification = (function () {
                                function Identification() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.Identification';
                                }
                                return Identification;
                            }());
                            customization.Identification = Identification;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_275.binding || (vim_275.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_276) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVmxnetOption = (function (_super) {
                                __extends(VirtualVmxnetOption, _super);
                                function VirtualVmxnetOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVmxnetOption';
                                }
                                return VirtualVmxnetOption;
                            }(device.VirtualEthernetCardOption));
                            device.VirtualVmxnetOption = VirtualVmxnetOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_276.binding || (vim_276.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_277) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVmxnet3Option = (function (_super) {
                                __extends(VirtualVmxnet3Option, _super);
                                function VirtualVmxnet3Option() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVmxnet3Option';
                                }
                                return VirtualVmxnet3Option;
                            }(device.VirtualVmxnetOption));
                            device.VirtualVmxnet3Option = VirtualVmxnet3Option;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_277.binding || (vim_277.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_278) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVmxnet3VrdmaOption = (function (_super) {
                                __extends(VirtualVmxnet3VrdmaOption, _super);
                                function VirtualVmxnet3VrdmaOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVmxnet3VrdmaOption';
                                }
                                return VirtualVmxnet3VrdmaOption;
                            }(device.VirtualVmxnet3Option));
                            device.VirtualVmxnet3VrdmaOption = VirtualVmxnet3VrdmaOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_278.binding || (vim_278.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_279) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ToolsConfigInfo = (function () {
                            function ToolsConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ToolsConfigInfo';
                            }
                            return ToolsConfigInfo;
                        }());
                        vm.ToolsConfigInfo = ToolsConfigInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_279.binding || (vim_279.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_280) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$DeviceBackingInfo = (function (_super) {
                                __extends(VirtualDevice$DeviceBackingInfo, _super);
                                function VirtualDevice$DeviceBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$DeviceBackingInfo';
                                }
                                return VirtualDevice$DeviceBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualDevice$DeviceBackingInfo = VirtualDevice$DeviceBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_280.binding || (vim_280.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_281) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPort$DeviceBackingInfo = (function (_super) {
                                __extends(VirtualSerialPort$DeviceBackingInfo, _super);
                                function VirtualSerialPort$DeviceBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$DeviceBackingInfo';
                                }
                                return VirtualSerialPort$DeviceBackingInfo;
                            }(device.VirtualDevice$DeviceBackingInfo));
                            device.VirtualSerialPort$DeviceBackingInfo = VirtualSerialPort$DeviceBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_281.binding || (vim_281.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_282) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualCdrom$PassthroughBackingInfo = (function (_super) {
                                __extends(VirtualCdrom$PassthroughBackingInfo, _super);
                                function VirtualCdrom$PassthroughBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$PassthroughBackingInfo';
                                }
                                return VirtualCdrom$PassthroughBackingInfo;
                            }(device.VirtualDevice$DeviceBackingInfo));
                            device.VirtualCdrom$PassthroughBackingInfo = VirtualCdrom$PassthroughBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_282.binding || (vim_282.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_283) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualUSB$USBBackingInfo = (function (_super) {
                                __extends(VirtualUSB$USBBackingInfo, _super);
                                function VirtualUSB$USBBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualUSB$USBBackingInfo';
                                }
                                return VirtualUSB$USBBackingInfo;
                            }(device.VirtualDevice$DeviceBackingInfo));
                            device.VirtualUSB$USBBackingInfo = VirtualUSB$USBBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_283.binding || (vim_283.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_284) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualCdrom$AtapiBackingInfo = (function (_super) {
                                __extends(VirtualCdrom$AtapiBackingInfo, _super);
                                function VirtualCdrom$AtapiBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$AtapiBackingInfo';
                                }
                                return VirtualCdrom$AtapiBackingInfo;
                            }(device.VirtualDevice$DeviceBackingInfo));
                            device.VirtualCdrom$AtapiBackingInfo = VirtualCdrom$AtapiBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_284.binding || (vim_284.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_285) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthrough$DeviceBackingInfo = (function (_super) {
                                __extends(VirtualPCIPassthrough$DeviceBackingInfo, _super);
                                function VirtualPCIPassthrough$DeviceBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$DeviceBackingInfo';
                                }
                                return VirtualPCIPassthrough$DeviceBackingInfo;
                            }(device.VirtualDevice$DeviceBackingInfo));
                            device.VirtualPCIPassthrough$DeviceBackingInfo = VirtualPCIPassthrough$DeviceBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_285.binding || (vim_285.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_286) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualUSB$RemoteHostBackingInfo = (function (_super) {
                                __extends(VirtualUSB$RemoteHostBackingInfo, _super);
                                function VirtualUSB$RemoteHostBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualUSB$RemoteHostBackingInfo';
                                }
                                return VirtualUSB$RemoteHostBackingInfo;
                            }(device.VirtualDevice$DeviceBackingInfo));
                            device.VirtualUSB$RemoteHostBackingInfo = VirtualUSB$RemoteHostBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_286.binding || (vim_286.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_287) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualEthernetCard$NetworkBackingInfo = (function (_super) {
                                __extends(VirtualEthernetCard$NetworkBackingInfo, _super);
                                function VirtualEthernetCard$NetworkBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$NetworkBackingInfo';
                                }
                                return VirtualEthernetCard$NetworkBackingInfo;
                            }(device.VirtualDevice$DeviceBackingInfo));
                            device.VirtualEthernetCard$NetworkBackingInfo = VirtualEthernetCard$NetworkBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_287.binding || (vim_287.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_288) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var GlobalIPSettings = (function () {
                                function GlobalIPSettings() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.GlobalIPSettings';
                                }
                                return GlobalIPSettings;
                            }());
                            customization.GlobalIPSettings = GlobalIPSettings;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_288.binding || (vim_288.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_289) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var InfraUpdateHaConfigInfo = (function () {
                            function InfraUpdateHaConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.InfraUpdateHaConfigInfo';
                            }
                            return InfraUpdateHaConfigInfo;
                        }());
                        cluster.InfraUpdateHaConfigInfo = InfraUpdateHaConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_289.binding || (vim_289.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_290) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$ConfigSpec = (function () {
                            function DistributedVirtualPort$ConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$ConfigSpec';
                            }
                            return DistributedVirtualPort$ConfigSpec;
                        }());
                        dvs.DistributedVirtualPort$ConfigSpec = DistributedVirtualPort$ConfigSpec;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_290.binding || (vim_290.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_291) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption$FileBackingOption = (function (_super) {
                                __extends(VirtualDeviceOption$FileBackingOption, _super);
                                function VirtualDeviceOption$FileBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$FileBackingOption';
                                }
                                return VirtualDeviceOption$FileBackingOption;
                            }(device.VirtualDeviceOption$BackingOption));
                            device.VirtualDeviceOption$FileBackingOption = VirtualDeviceOption$FileBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_291.binding || (vim_291.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_292) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPortOption$FileBackingOption = (function (_super) {
                                __extends(VirtualSerialPortOption$FileBackingOption, _super);
                                function VirtualSerialPortOption$FileBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$FileBackingOption';
                                }
                                return VirtualSerialPortOption$FileBackingOption;
                            }(device.VirtualDeviceOption$FileBackingOption));
                            device.VirtualSerialPortOption$FileBackingOption = VirtualSerialPortOption$FileBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_292.binding || (vim_292.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_293) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var Password = (function () {
                                function Password() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.Password';
                                }
                                return Password;
                            }());
                            customization.Password = Password;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_293.binding || (vim_293.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_294) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ScsiLun$Descriptor = (function () {
                            function ScsiLun$Descriptor() {
                                this._type = 'com.vmware.vim.binding.vim.host.ScsiLun$Descriptor';
                            }
                            return ScsiLun$Descriptor;
                        }());
                        host.ScsiLun$Descriptor = ScsiLun$Descriptor;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_294.binding || (vim_294.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_295) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FeatureRequirement = (function () {
                            function FeatureRequirement() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FeatureRequirement';
                            }
                            return FeatureRequirement;
                        }());
                        vm.FeatureRequirement = FeatureRequirement;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_295.binding || (vim_295.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_296) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$UplinkLacpPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$UplinkLacpPolicy, _super);
                            function VmwareDistributedVirtualSwitch$UplinkLacpPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$UplinkLacpPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$UplinkLacpPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$UplinkLacpPolicy = VmwareDistributedVirtualSwitch$UplinkLacpPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_296.binding || (vim_296.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_297) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var DatastoreSystem$VvolDatastoreSpec = (function () {
                            function DatastoreSystem$VvolDatastoreSpec() {
                                this._type = 'com.vmware.vim.binding.vim.host.DatastoreSystem$VvolDatastoreSpec';
                            }
                            return DatastoreSystem$VvolDatastoreSpec;
                        }());
                        host.DatastoreSystem$VvolDatastoreSpec = DatastoreSystem$VvolDatastoreSpec;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_297.binding || (vim_297.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_298) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var IPSettings = (function () {
                                function IPSettings() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.IPSettings';
                                }
                                return IPSettings;
                            }());
                            customization.IPSettings = IPSettings;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_298.binding || (vim_298.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_299) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$URIBackingInfo = (function (_super) {
                                __extends(VirtualDevice$URIBackingInfo, _super);
                                function VirtualDevice$URIBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$URIBackingInfo';
                                }
                                return VirtualDevice$URIBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualDevice$URIBackingInfo = VirtualDevice$URIBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_299.binding || (vim_299.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_300) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPort$URIBackingInfo = (function (_super) {
                                __extends(VirtualSerialPort$URIBackingInfo, _super);
                                function VirtualSerialPort$URIBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$URIBackingInfo';
                                }
                                return VirtualSerialPort$URIBackingInfo;
                            }(device.VirtualDevice$URIBackingInfo));
                            device.VirtualSerialPort$URIBackingInfo = VirtualSerialPort$URIBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_300.binding || (vim_300.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_301) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var ConfigInfo$ClusterInfo = (function () {
                                function ConfigInfo$ClusterInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.ConfigInfo$ClusterInfo';
                                }
                                return ConfigInfo$ClusterInfo;
                            }());
                            host.ConfigInfo$ClusterInfo = ConfigInfo$ClusterInfo;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_301.binding || (vim_301.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_302) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FaultToleranceVMConfigSpec = (function () {
                            function FaultToleranceVMConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FaultToleranceVMConfigSpec';
                            }
                            return FaultToleranceVMConfigSpec;
                        }());
                        vm.FaultToleranceVMConfigSpec = FaultToleranceVMConfigSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_302.binding || (vim_302.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_303) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ReplicationConfigSpec = (function () {
                            function ReplicationConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ReplicationConfigSpec';
                            }
                            return ReplicationConfigSpec;
                        }());
                        vm.ReplicationConfigSpec = ReplicationConfigSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_303.binding || (vim_303.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_304) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var NetworkShaperInfo = (function () {
                            function NetworkShaperInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.NetworkShaperInfo';
                            }
                            return NetworkShaperInfo;
                        }());
                        vm.NetworkShaperInfo = NetworkShaperInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_304.binding || (vim_304.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_305) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FaultToleranceVMConfigSpec$FaultToleranceDiskSpec = (function () {
                            function FaultToleranceVMConfigSpec$FaultToleranceDiskSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FaultToleranceVMConfigSpec$FaultToleranceDiskSpec';
                            }
                            return FaultToleranceVMConfigSpec$FaultToleranceDiskSpec;
                        }());
                        vm.FaultToleranceVMConfigSpec$FaultToleranceDiskSpec = FaultToleranceVMConfigSpec$FaultToleranceDiskSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_305.binding || (vim_305.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_306) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualNVDIMMOption = (function (_super) {
                                __extends(VirtualNVDIMMOption, _super);
                                function VirtualNVDIMMOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualNVDIMMOption';
                                }
                                return VirtualNVDIMMOption;
                            }(device.VirtualDeviceOption));
                            device.VirtualNVDIMMOption = VirtualNVDIMMOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_306.binding || (vim_306.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_307) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var DistributedVirtualPort$TrafficShapingPolicy = (function (_super) {
                            __extends(DistributedVirtualPort$TrafficShapingPolicy, _super);
                            function DistributedVirtualPort$TrafficShapingPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$TrafficShapingPolicy';
                            }
                            return DistributedVirtualPort$TrafficShapingPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.DistributedVirtualPort$TrafficShapingPolicy = DistributedVirtualPort$TrafficShapingPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_307.binding || (vim_307.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_308) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ScsiLun$Capabilities = (function () {
                            function ScsiLun$Capabilities() {
                                this._type = 'com.vmware.vim.binding.vim.host.ScsiLun$Capabilities';
                            }
                            return ScsiLun$Capabilities;
                        }());
                        host.ScsiLun$Capabilities = ScsiLun$Capabilities;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_308.binding || (vim_308.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var profile;
                    (function (profile) {
                        var CapabilityConstraints = (function () {
                            function CapabilityConstraints() {
                                this._type = 'com.vmware.vim.binding.pbm.profile.CapabilityConstraints';
                            }
                            return CapabilityConstraints;
                        }());
                        profile.CapabilityConstraints = CapabilityConstraints;
                    })(profile = pbm.profile || (pbm.profile = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var profile;
                    (function (profile) {
                        var SubProfileCapabilityConstraints = (function (_super) {
                            __extends(SubProfileCapabilityConstraints, _super);
                            function SubProfileCapabilityConstraints() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.pbm.profile.SubProfileCapabilityConstraints';
                            }
                            return SubProfileCapabilityConstraints;
                        }(profile.CapabilityConstraints));
                        profile.SubProfileCapabilityConstraints = SubProfileCapabilityConstraints;
                    })(profile = pbm.profile || (pbm.profile = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var pbm;
                (function (pbm) {
                    var capability;
                    (function (capability) {
                        var ConstraintInstance = (function () {
                            function ConstraintInstance() {
                                this._type = 'com.vmware.vim.binding.pbm.capability.ConstraintInstance';
                            }
                            return ConstraintInstance;
                        }());
                        capability.ConstraintInstance = ConstraintInstance;
                    })(capability = pbm.capability || (pbm.capability = {}));
                })(pbm = binding.pbm || (binding.pbm = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_309) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$MacLearningPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$MacLearningPolicy, _super);
                            function VmwareDistributedVirtualSwitch$MacLearningPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$MacLearningPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$MacLearningPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$MacLearningPolicy = VmwareDistributedVirtualSwitch$MacLearningPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_309.binding || (vim_309.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_310) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSriovEthernetCardOption = (function (_super) {
                                __extends(VirtualSriovEthernetCardOption, _super);
                                function VirtualSriovEthernetCardOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCardOption';
                                }
                                return VirtualSriovEthernetCardOption;
                            }(device.VirtualEthernetCardOption));
                            device.VirtualSriovEthernetCardOption = VirtualSriovEthernetCardOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_310.binding || (vim_310.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_311) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPort$PipeBackingInfo = (function (_super) {
                                __extends(VirtualSerialPort$PipeBackingInfo, _super);
                                function VirtualSerialPort$PipeBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$PipeBackingInfo';
                                }
                                return VirtualSerialPort$PipeBackingInfo;
                            }(device.VirtualDevice$PipeBackingInfo));
                            device.VirtualSerialPort$PipeBackingInfo = VirtualSerialPort$PipeBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_311.binding || (vim_311.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_312) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ScsiLun = (function (_super) {
                            __extends(ScsiLun, _super);
                            function ScsiLun() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.host.ScsiLun';
                            }
                            return ScsiLun;
                        }(host.Device));
                        host.ScsiLun = ScsiLun;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_312.binding || (vim_312.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_313) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ScsiDisk = (function (_super) {
                            __extends(ScsiDisk, _super);
                            function ScsiDisk() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.host.ScsiDisk';
                            }
                            return ScsiDisk;
                        }(host.ScsiLun));
                        host.ScsiDisk = ScsiDisk;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_313.binding || (vim_313.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_314) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var ConfigInfo$NetworkInfo$PortConfig = (function () {
                                function ConfigInfo$NetworkInfo$PortConfig() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.ConfigInfo$NetworkInfo$PortConfig';
                                }
                                return ConfigInfo$NetworkInfo$PortConfig;
                            }());
                            host.ConfigInfo$NetworkInfo$PortConfig = ConfigInfo$NetworkInfo$PortConfig;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_314.binding || (vim_314.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_315) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ProtocolEndpoint = (function () {
                            function ProtocolEndpoint() {
                                this._type = 'com.vmware.vim.binding.vim.host.ProtocolEndpoint';
                            }
                            return ProtocolEndpoint;
                        }());
                        host.ProtocolEndpoint = ProtocolEndpoint;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_315.binding || (vim_315.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_316) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var replication;
                        (function (replication) {
                            var ReplicationSpec = (function () {
                                function ReplicationSpec() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.replication.ReplicationSpec';
                                }
                                return ReplicationSpec;
                            }());
                            replication.ReplicationSpec = ReplicationSpec;
                        })(replication = vm.replication || (vm.replication = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_316.binding || (vim_316.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_317) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var RelocateSpec$DiskLocator = (function () {
                            function RelocateSpec$DiskLocator() {
                                this._type = 'com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator';
                            }
                            return RelocateSpec$DiskLocator;
                        }());
                        vm.RelocateSpec$DiskLocator = RelocateSpec$DiskLocator;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_317.binding || (vim_317.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_318) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ext;
                    (function (ext) {
                        var ManagedByInfo = (function () {
                            function ManagedByInfo() {
                                this._type = 'com.vmware.vim.binding.vim.ext.ManagedByInfo';
                            }
                            return ManagedByInfo;
                        }());
                        ext.ManagedByInfo = ManagedByInfo;
                    })(ext = vim.ext || (vim.ext = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_318.binding || (vim_318.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_319) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$RemoteDeviceBackingInfo = (function (_super) {
                                __extends(VirtualDevice$RemoteDeviceBackingInfo, _super);
                                function VirtualDevice$RemoteDeviceBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$RemoteDeviceBackingInfo';
                                }
                                return VirtualDevice$RemoteDeviceBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualDevice$RemoteDeviceBackingInfo = VirtualDevice$RemoteDeviceBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_319.binding || (vim_319.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_320) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualUSB$RemoteClientBackingInfo = (function (_super) {
                                __extends(VirtualUSB$RemoteClientBackingInfo, _super);
                                function VirtualUSB$RemoteClientBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualUSB$RemoteClientBackingInfo';
                                }
                                return VirtualUSB$RemoteClientBackingInfo;
                            }(device.VirtualDevice$RemoteDeviceBackingInfo));
                            device.VirtualUSB$RemoteClientBackingInfo = VirtualUSB$RemoteClientBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_320.binding || (vim_320.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_321) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualCdrom$RemotePassthroughBackingInfo = (function (_super) {
                                __extends(VirtualCdrom$RemotePassthroughBackingInfo, _super);
                                function VirtualCdrom$RemotePassthroughBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$RemotePassthroughBackingInfo';
                                }
                                return VirtualCdrom$RemotePassthroughBackingInfo;
                            }(device.VirtualDevice$RemoteDeviceBackingInfo));
                            device.VirtualCdrom$RemotePassthroughBackingInfo = VirtualCdrom$RemotePassthroughBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_321.binding || (vim_321.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_322) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualCdrom$RemoteAtapiBackingInfo = (function (_super) {
                                __extends(VirtualCdrom$RemoteAtapiBackingInfo, _super);
                                function VirtualCdrom$RemoteAtapiBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$RemoteAtapiBackingInfo';
                                }
                                return VirtualCdrom$RemoteAtapiBackingInfo;
                            }(device.VirtualDevice$RemoteDeviceBackingInfo));
                            device.VirtualCdrom$RemoteAtapiBackingInfo = VirtualCdrom$RemoteAtapiBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_322.binding || (vim_322.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_323) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var VmToolsMonitoringSettings = (function () {
                            function VmToolsMonitoringSettings() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.VmToolsMonitoringSettings';
                            }
                            return VmToolsMonitoringSettings;
                        }());
                        cluster.VmToolsMonitoringSettings = VmToolsMonitoringSettings;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_323.binding || (vim_323.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_324) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var PolicyMetadata = (function () {
                            function PolicyMetadata() {
                                this._type = 'com.vmware.vim.binding.vim.profile.PolicyMetadata';
                            }
                            return PolicyMetadata;
                        }());
                        profile.PolicyMetadata = PolicyMetadata;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_324.binding || (vim_324.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_325) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var MacAddress = (function (_super) {
                        __extends(MacAddress, _super);
                        function MacAddress() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.MacAddress';
                        }
                        return MacAddress;
                    }(vim.NegatableExpression));
                    vim.MacAddress = MacAddress;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_325.binding || (vim_325.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_326) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var Options = (function () {
                                function Options() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.Options';
                                }
                                return Options;
                            }());
                            customization.Options = Options;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_326.binding || (vim_326.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_327) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var LinuxOptions = (function (_super) {
                                __extends(LinuxOptions, _super);
                                function LinuxOptions() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.LinuxOptions';
                                }
                                return LinuxOptions;
                            }(customization.Options));
                            customization.LinuxOptions = LinuxOptions;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_327.binding || (vim_327.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_328) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ScsiDisk$Partition = (function () {
                            function ScsiDisk$Partition() {
                                this._type = 'com.vmware.vim.binding.vim.host.ScsiDisk$Partition';
                            }
                            return ScsiDisk$Partition;
                        }());
                        host.ScsiDisk$Partition = ScsiDisk$Partition;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_328.binding || (vim_328.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_329) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var IpV6Generator = (function () {
                                function IpV6Generator() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.IpV6Generator';
                                }
                                return IpV6Generator;
                            }());
                            customization.IpV6Generator = IpV6Generator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_329.binding || (vim_329.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_330) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var UnknownIpV6Generator = (function (_super) {
                                __extends(UnknownIpV6Generator, _super);
                                function UnknownIpV6Generator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.UnknownIpV6Generator';
                                }
                                return UnknownIpV6Generator;
                            }(customization.IpV6Generator));
                            customization.UnknownIpV6Generator = UnknownIpV6Generator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_330.binding || (vim_330.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_331) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var StatelessIpV6Generator = (function (_super) {
                                __extends(StatelessIpV6Generator, _super);
                                function StatelessIpV6Generator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.StatelessIpV6Generator';
                                }
                                return StatelessIpV6Generator;
                            }(customization.IpV6Generator));
                            customization.StatelessIpV6Generator = StatelessIpV6Generator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_331.binding || (vim_331.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_332) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var AutoIpV6Generator = (function (_super) {
                                __extends(AutoIpV6Generator, _super);
                                function AutoIpV6Generator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.AutoIpV6Generator';
                                }
                                return AutoIpV6Generator;
                            }(customization.IpV6Generator));
                            customization.AutoIpV6Generator = AutoIpV6Generator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_332.binding || (vim_332.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_333) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var CustomIpV6Generator = (function (_super) {
                                __extends(CustomIpV6Generator, _super);
                                function CustomIpV6Generator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.CustomIpV6Generator';
                                }
                                return CustomIpV6Generator;
                            }(customization.IpV6Generator));
                            customization.CustomIpV6Generator = CustomIpV6Generator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_333.binding || (vim_333.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_334) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var FixedIpV6 = (function (_super) {
                                __extends(FixedIpV6, _super);
                                function FixedIpV6() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.FixedIpV6';
                                }
                                return FixedIpV6;
                            }(customization.IpV6Generator));
                            customization.FixedIpV6 = FixedIpV6;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_334.binding || (vim_334.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_335) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var DhcpIpV6Generator = (function (_super) {
                                __extends(DhcpIpV6Generator, _super);
                                function DhcpIpV6Generator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.DhcpIpV6Generator';
                                }
                                return DhcpIpV6Generator;
                            }(customization.IpV6Generator));
                            customization.DhcpIpV6Generator = DhcpIpV6Generator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_335.binding || (vim_335.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_336) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FaultToleranceConfigSpec = (function () {
                            function FaultToleranceConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vm.FaultToleranceConfigSpec';
                            }
                            return FaultToleranceConfigSpec;
                        }());
                        vm.FaultToleranceConfigSpec = FaultToleranceConfigSpec;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_336.binding || (vim_336.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var impl;
                (function (impl) {
                    var vmodl;
                    (function (vmodl) {
                        var KeyAnyValueImpl = (function () {
                            function KeyAnyValueImpl() {
                                this._type = 'com.vmware.vim.binding.impl.vmodl.KeyAnyValueImpl';
                            }
                            return KeyAnyValueImpl;
                        }());
                        vmodl.KeyAnyValueImpl = KeyAnyValueImpl;
                    })(vmodl = impl.vmodl || (impl.vmodl = {}));
                })(impl = binding.impl || (binding.impl = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_337) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig, _super);
                            function VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig';
                            }
                            return VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig;
                        }(dvs.VmwareDistributedVirtualSwitch$VmwareHealthCheckConfig));
                        dvs.VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig = VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_337.binding || (vim_337.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_338) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var encryption;
                    (function (encryption) {
                        var KmipClusterInfo = (function () {
                            function KmipClusterInfo() {
                                this._type = 'com.vmware.vim.binding.vim.encryption.KmipClusterInfo';
                            }
                            return KmipClusterInfo;
                        }());
                        encryption.KmipClusterInfo = KmipClusterInfo;
                    })(encryption = vim.encryption || (vim.encryption = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_338.binding || (vim_338.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_339) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$FailureCriteria = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$FailureCriteria, _super);
                            function VmwareDistributedVirtualSwitch$FailureCriteria() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$FailureCriteria';
                            }
                            return VmwareDistributedVirtualSwitch$FailureCriteria;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$FailureCriteria = VmwareDistributedVirtualSwitch$FailureCriteria;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_339.binding || (vim_339.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_340) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var IpGenerator = (function () {
                                function IpGenerator() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.IpGenerator';
                                }
                                return IpGenerator;
                            }());
                            customization.IpGenerator = IpGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_340.binding || (vim_340.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_341) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var UnknownIpGenerator = (function (_super) {
                                __extends(UnknownIpGenerator, _super);
                                function UnknownIpGenerator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.UnknownIpGenerator';
                                }
                                return UnknownIpGenerator;
                            }(customization.IpGenerator));
                            customization.UnknownIpGenerator = UnknownIpGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_341.binding || (vim_341.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_342) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var FixedIp = (function (_super) {
                                __extends(FixedIp, _super);
                                function FixedIp() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.FixedIp';
                                }
                                return FixedIp;
                            }(customization.IpGenerator));
                            customization.FixedIp = FixedIp;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_342.binding || (vim_342.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_343) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var CustomIpGenerator = (function (_super) {
                                __extends(CustomIpGenerator, _super);
                                function CustomIpGenerator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.CustomIpGenerator';
                                }
                                return CustomIpGenerator;
                            }(customization.IpGenerator));
                            customization.CustomIpGenerator = CustomIpGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_343.binding || (vim_343.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_344) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var DhcpIpGenerator = (function (_super) {
                                __extends(DhcpIpGenerator, _super);
                                function DhcpIpGenerator() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.DhcpIpGenerator';
                                }
                                return DhcpIpGenerator;
                            }(customization.IpGenerator));
                            customization.DhcpIpGenerator = DhcpIpGenerator;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_344.binding || (vim_344.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_345) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRule$DropAction = (function (_super) {
                            __extends(TrafficRule$DropAction, _super);
                            function TrafficRule$DropAction() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRule$DropAction';
                            }
                            return TrafficRule$DropAction;
                        }(dvs.TrafficRule$Action));
                        dvs.TrafficRule$DropAction = TrafficRule$DropAction;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_345.binding || (vim_345.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_346) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var TrafficRuleset = (function () {
                            function TrafficRuleset() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.TrafficRuleset';
                            }
                            return TrafficRuleset;
                        }());
                        dvs.TrafficRuleset = TrafficRuleset;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_346.binding || (vim_346.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_347) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var Policy = (function () {
                            function Policy() {
                                this._type = 'com.vmware.vim.binding.vim.profile.Policy';
                            }
                            return Policy;
                        }());
                        profile.Policy = Policy;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_347.binding || (vim_347.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_348) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var StringExpression = (function (_super) {
                        __extends(StringExpression, _super);
                        function StringExpression() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.StringExpression';
                        }
                        return StringExpression;
                    }(vim.NegatableExpression));
                    vim.StringExpression = StringExpression;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_348.binding || (vim_348.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_349) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var encryption;
                    (function (encryption) {
                        var CryptoKeyId = (function () {
                            function CryptoKeyId() {
                                this._type = 'com.vmware.vim.binding.vim.encryption.CryptoKeyId';
                            }
                            return CryptoKeyId;
                        }());
                        encryption.CryptoKeyId = CryptoKeyId;
                    })(encryption = vim.encryption || (vim.encryption = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_349.binding || (vim_349.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_350) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var PciPassthroughInfo = (function (_super) {
                            __extends(PciPassthroughInfo, _super);
                            function PciPassthroughInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.PciPassthroughInfo';
                            }
                            return PciPassthroughInfo;
                        }(vm.TargetInfo));
                        vm.PciPassthroughInfo = PciPassthroughInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_350.binding || (vim_350.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_351) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var SriovInfo = (function (_super) {
                            __extends(SriovInfo, _super);
                            function SriovInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.SriovInfo';
                            }
                            return SriovInfo;
                        }(vm.PciPassthroughInfo));
                        vm.SriovInfo = SriovInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_351.binding || (vim_351.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_352) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var FloppyInfo = (function (_super) {
                            __extends(FloppyInfo, _super);
                            function FloppyInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.FloppyInfo';
                            }
                            return FloppyInfo;
                        }(vm.TargetInfo));
                        vm.FloppyInfo = FloppyInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_352.binding || (vim_352.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_353) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var CompositePolicyOptionMetadata = (function (_super) {
                            __extends(CompositePolicyOptionMetadata, _super);
                            function CompositePolicyOptionMetadata() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.profile.CompositePolicyOptionMetadata';
                            }
                            return CompositePolicyOptionMetadata;
                        }(profile.PolicyOptionMetadata));
                        profile.CompositePolicyOptionMetadata = CompositePolicyOptionMetadata;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_353.binding || (vim_353.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_354) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vApp;
                    (function (vApp) {
                        var VmConfigSpec = (function () {
                            function VmConfigSpec() {
                                this._type = 'com.vmware.vim.binding.vim.vApp.VmConfigSpec';
                            }
                            return VmConfigSpec;
                        }());
                        vApp.VmConfigSpec = VmConfigSpec;
                    })(vApp = vim.vApp || (vim.vApp = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_354.binding || (vim_354.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_355) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPortOption$ThinPrintBackingOption = (function (_super) {
                                __extends(VirtualSerialPortOption$ThinPrintBackingOption, _super);
                                function VirtualSerialPortOption$ThinPrintBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$ThinPrintBackingOption';
                                }
                                return VirtualSerialPortOption$ThinPrintBackingOption;
                            }(device.VirtualDeviceOption$BackingOption));
                            device.VirtualSerialPortOption$ThinPrintBackingOption = VirtualSerialPortOption$ThinPrintBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_355.binding || (vim_355.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_356) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$LacpGroupConfig = (function () {
                            function VmwareDistributedVirtualSwitch$LacpGroupConfig() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig';
                            }
                            return VmwareDistributedVirtualSwitch$LacpGroupConfig;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig = VmwareDistributedVirtualSwitch$LacpGroupConfig;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_356.binding || (vim_356.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_357) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var VFlashManager$VFlashCacheConfigInfo$VFlashModuleConfigOption = (function () {
                            function VFlashManager$VFlashCacheConfigInfo$VFlashModuleConfigOption() {
                                this._type = 'com.vmware.vim.binding.vim.host.VFlashManager$VFlashCacheConfigInfo$VFlashModuleConfigOption';
                            }
                            return VFlashManager$VFlashCacheConfigInfo$VFlashModuleConfigOption;
                        }());
                        host.VFlashManager$VFlashCacheConfigInfo$VFlashModuleConfigOption = VFlashManager$VFlashCacheConfigInfo$VFlashModuleConfigOption;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_357.binding || (vim_357.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_358) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var KeyedOpaqueBlob = (function () {
                            function KeyedOpaqueBlob() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.KeyedOpaqueBlob';
                            }
                            return KeyedOpaqueBlob;
                        }());
                        dvs.KeyedOpaqueBlob = KeyedOpaqueBlob;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_358.binding || (vim_358.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_359) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var ScsiLun$DurableName = (function () {
                            function ScsiLun$DurableName() {
                                this._type = 'com.vmware.vim.binding.vim.host.ScsiLun$DurableName';
                            }
                            return ScsiLun$DurableName;
                        }());
                        host.ScsiLun$DurableName = ScsiLun$DurableName;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_359.binding || (vim_359.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_360) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var UserData = (function () {
                                function UserData() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.UserData';
                                }
                                return UserData;
                            }());
                            customization.UserData = UserData;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_360.binding || (vim_360.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_361) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var FeatureMask = (function () {
                            function FeatureMask() {
                                this._type = 'com.vmware.vim.binding.vim.host.FeatureMask';
                            }
                            return FeatureMask;
                        }());
                        host.FeatureMask = FeatureMask;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_361.binding || (vim_361.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_362) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice$FileBackingInfo = (function (_super) {
                                __extends(VirtualDevice$FileBackingInfo, _super);
                                function VirtualDevice$FileBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice$FileBackingInfo';
                                }
                                return VirtualDevice$FileBackingInfo;
                            }(device.VirtualDevice$BackingInfo));
                            device.VirtualDevice$FileBackingInfo = VirtualDevice$FileBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_362.binding || (vim_362.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_363) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPort$FileBackingInfo = (function (_super) {
                                __extends(VirtualSerialPort$FileBackingInfo, _super);
                                function VirtualSerialPort$FileBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$FileBackingInfo';
                                }
                                return VirtualSerialPort$FileBackingInfo;
                            }(device.VirtualDevice$FileBackingInfo));
                            device.VirtualSerialPort$FileBackingInfo = VirtualSerialPort$FileBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_363.binding || (vim_363.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_364) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualCdrom$IsoBackingInfo = (function (_super) {
                                __extends(VirtualCdrom$IsoBackingInfo, _super);
                                function VirtualCdrom$IsoBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$IsoBackingInfo';
                                }
                                return VirtualCdrom$IsoBackingInfo;
                            }(device.VirtualDevice$FileBackingInfo));
                            device.VirtualCdrom$IsoBackingInfo = VirtualCdrom$IsoBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_364.binding || (vim_364.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_365) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDisk$FlatVer2BackingInfo = (function (_super) {
                                __extends(VirtualDisk$FlatVer2BackingInfo, _super);
                                function VirtualDisk$FlatVer2BackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDisk$FlatVer2BackingInfo';
                                }
                                return VirtualDisk$FlatVer2BackingInfo;
                            }(device.VirtualDevice$FileBackingInfo));
                            device.VirtualDisk$FlatVer2BackingInfo = VirtualDisk$FlatVer2BackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_365.binding || (vim_365.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_366) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualNVDIMM$BackingInfo = (function (_super) {
                                __extends(VirtualNVDIMM$BackingInfo, _super);
                                function VirtualNVDIMM$BackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM$BackingInfo';
                                }
                                return VirtualNVDIMM$BackingInfo;
                            }(device.VirtualDevice$FileBackingInfo));
                            device.VirtualNVDIMM$BackingInfo = VirtualNVDIMM$BackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_366.binding || (vim_366.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_367) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDisk$LocalPMemBackingInfo = (function (_super) {
                                __extends(VirtualDisk$LocalPMemBackingInfo, _super);
                                function VirtualDisk$LocalPMemBackingInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDisk$LocalPMemBackingInfo';
                                }
                                return VirtualDisk$LocalPMemBackingInfo;
                            }(device.VirtualDevice$FileBackingInfo));
                            device.VirtualDisk$LocalPMemBackingInfo = VirtualDisk$LocalPMemBackingInfo;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_367.binding || (vim_367.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_368) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var host;
                    (function (host) {
                        var DiskDimensions$Lba = (function () {
                            function DiskDimensions$Lba() {
                                this._type = 'com.vmware.vim.binding.vim.host.DiskDimensions$Lba';
                            }
                            return DiskDimensions$Lba;
                        }());
                        host.DiskDimensions$Lba = DiskDimensions$Lba;
                    })(host = vim.host || (vim.host = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_368.binding || (vim_368.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_369) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ResourceAllocationInfo = (function () {
                        function ResourceAllocationInfo() {
                            this._type = 'com.vmware.vim.binding.vim.ResourceAllocationInfo';
                        }
                        return ResourceAllocationInfo;
                    }());
                    vim.ResourceAllocationInfo = ResourceAllocationInfo;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_369.binding || (vim_369.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_370) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DasConfigInfo = (function () {
                            function DasConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DasConfigInfo';
                            }
                            return DasConfigInfo;
                        }());
                        cluster.DasConfigInfo = DasConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_370.binding || (vim_370.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_371) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var VmReadiness = (function () {
                            function VmReadiness() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.VmReadiness';
                            }
                            return VmReadiness;
                        }());
                        cluster.VmReadiness = VmReadiness;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_371.binding || (vim_371.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_372) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var StringPolicy = (function (_super) {
                        __extends(StringPolicy, _super);
                        function StringPolicy() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.StringPolicy';
                        }
                        return StringPolicy;
                    }(vim.InheritablePolicy));
                    vim.StringPolicy = StringPolicy;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_372.binding || (vim_372.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_373) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ScsiDiskDeviceInfo = (function (_super) {
                            __extends(ScsiDiskDeviceInfo, _super);
                            function ScsiDiskDeviceInfo() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.vm.ScsiDiskDeviceInfo';
                            }
                            return ScsiDiskDeviceInfo;
                        }(vm.DiskDeviceInfo));
                        vm.ScsiDiskDeviceInfo = ScsiDiskDeviceInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_373.binding || (vim_373.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_374) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ForkConfigInfo = (function () {
                            function ForkConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ForkConfigInfo';
                            }
                            return ForkConfigInfo;
                        }());
                        vm.ForkConfigInfo = ForkConfigInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_374.binding || (vim_374.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_375) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$SecurityPolicy = (function (_super) {
                            __extends(VmwareDistributedVirtualSwitch$SecurityPolicy, _super);
                            function VmwareDistributedVirtualSwitch$SecurityPolicy() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$SecurityPolicy';
                            }
                            return VmwareDistributedVirtualSwitch$SecurityPolicy;
                        }(com.vmware.vim.binding.vim.InheritablePolicy));
                        dvs.VmwareDistributedVirtualSwitch$SecurityPolicy = VmwareDistributedVirtualSwitch$SecurityPolicy;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_375.binding || (vim_375.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_376) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var Recommendation = (function () {
                            function Recommendation() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.Recommendation';
                            }
                            return Recommendation;
                        }());
                        cluster.Recommendation = Recommendation;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_376.binding || (vim_376.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_377) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var RuntimeInfo$DasProtectionState = (function () {
                            function RuntimeInfo$DasProtectionState() {
                                this._type = 'com.vmware.vim.binding.vim.vm.RuntimeInfo$DasProtectionState';
                            }
                            return RuntimeInfo$DasProtectionState;
                        }());
                        vm.RuntimeInfo$DasProtectionState = RuntimeInfo$DasProtectionState;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_377.binding || (vim_377.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_378) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var customization;
                        (function (customization) {
                            var WinOptions = (function (_super) {
                                __extends(WinOptions, _super);
                                function WinOptions() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.customization.WinOptions';
                                }
                                return WinOptions;
                            }(customization.Options));
                            customization.WinOptions = WinOptions;
                        })(customization = vm.customization || (vm.customization = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_378.binding || (vim_378.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_379) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var DpmConfigInfo = (function () {
                            function DpmConfigInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.DpmConfigInfo';
                            }
                            return DpmConfigInfo;
                        }());
                        cluster.DpmConfigInfo = DpmConfigInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_379.binding || (vim_379.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_380) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var VsanDiskInfo = (function () {
                                function VsanDiskInfo() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.VsanDiskInfo';
                                }
                                return VsanDiskInfo;
                            }());
                            host.VsanDiskInfo = VsanDiskInfo;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_380.binding || (vim_380.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_381) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var RuleInfo = (function () {
                            function RuleInfo() {
                                this._type = 'com.vmware.vim.binding.vim.cluster.RuleInfo';
                            }
                            return RuleInfo;
                        }());
                        cluster.RuleInfo = RuleInfo;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_381.binding || (vim_381.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_382) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var storageDrs;
                    (function (storageDrs) {
                        var VirtualDiskRuleSpec = (function (_super) {
                            __extends(VirtualDiskRuleSpec, _super);
                            function VirtualDiskRuleSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.storageDrs.VirtualDiskRuleSpec';
                            }
                            return VirtualDiskRuleSpec;
                        }(com.vmware.vim.binding.vim.cluster.RuleInfo));
                        storageDrs.VirtualDiskRuleSpec = VirtualDiskRuleSpec;
                    })(storageDrs = vim.storageDrs || (vim.storageDrs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_382.binding || (vim_382.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_383) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var storageDrs;
                    (function (storageDrs) {
                        var VirtualDiskAntiAffinityRuleSpec = (function (_super) {
                            __extends(VirtualDiskAntiAffinityRuleSpec, _super);
                            function VirtualDiskAntiAffinityRuleSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.storageDrs.VirtualDiskAntiAffinityRuleSpec';
                            }
                            return VirtualDiskAntiAffinityRuleSpec;
                        }(com.vmware.vim.binding.vim.cluster.RuleInfo));
                        storageDrs.VirtualDiskAntiAffinityRuleSpec = VirtualDiskAntiAffinityRuleSpec;
                    })(storageDrs = vim.storageDrs || (vim.storageDrs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_383.binding || (vim_383.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_384) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var LegacyNetworkSwitchInfo = (function () {
                            function LegacyNetworkSwitchInfo() {
                                this._type = 'com.vmware.vim.binding.vim.vm.LegacyNetworkSwitchInfo';
                            }
                            return LegacyNetworkSwitchInfo;
                        }());
                        vm.LegacyNetworkSwitchInfo = LegacyNetworkSwitchInfo;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_384.binding || (vim_384.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_385) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var ReplicationConfigSpec$DiskSettings = (function () {
                            function ReplicationConfigSpec$DiskSettings() {
                                this._type = 'com.vmware.vim.binding.vim.vm.ReplicationConfigSpec$DiskSettings';
                            }
                            return ReplicationConfigSpec$DiskSettings;
                        }());
                        vm.ReplicationConfigSpec$DiskSettings = ReplicationConfigSpec$DiskSettings;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_385.binding || (vim_385.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_386) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var IpAddress = (function (_super) {
                        __extends(IpAddress, _super);
                        function IpAddress() {
                            _super.apply(this, arguments);
                            this._type = 'com.vmware.vim.binding.vim.IpAddress';
                        }
                        return IpAddress;
                    }(vim.NegatableExpression));
                    vim.IpAddress = IpAddress;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_386.binding || (vim_386.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_387) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var dvs;
                    (function (dvs) {
                        var VmwareDistributedVirtualSwitch$VspanPorts = (function () {
                            function VmwareDistributedVirtualSwitch$VspanPorts() {
                                this._type = 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VspanPorts';
                            }
                            return VmwareDistributedVirtualSwitch$VspanPorts;
                        }());
                        dvs.VmwareDistributedVirtualSwitch$VspanPorts = VmwareDistributedVirtualSwitch$VspanPorts;
                    })(dvs = vim.dvs || (vim.dvs = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_387.binding || (vim_387.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_388) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var ComputeResource$ConfigInfo = (function () {
                        function ComputeResource$ConfigInfo() {
                            this._type = 'com.vmware.vim.binding.vim.ComputeResource$ConfigInfo';
                        }
                        return ComputeResource$ConfigInfo;
                    }());
                    vim.ComputeResource$ConfigInfo = ComputeResource$ConfigInfo;
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_388.binding || (vim_388.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_389) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var cluster;
                    (function (cluster) {
                        var ConfigInfoEx = (function (_super) {
                            __extends(ConfigInfoEx, _super);
                            function ConfigInfoEx() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vim.binding.vim.cluster.ConfigInfoEx';
                            }
                            return ConfigInfoEx;
                        }(com.vmware.vim.binding.vim.ComputeResource$ConfigInfo));
                        cluster.ConfigInfoEx = ConfigInfoEx;
                    })(cluster = vim.cluster || (vim.cluster = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_389.binding || (vim_389.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_390) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var Summary$StorageSummary = (function () {
                            function Summary$StorageSummary() {
                                this._type = 'com.vmware.vim.binding.vim.vm.Summary$StorageSummary';
                            }
                            return Summary$StorageSummary;
                        }());
                        vm.Summary$StorageSummary = Summary$StorageSummary;
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_390.binding || (vim_390.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_391) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDeviceOption$DeviceBackingOption = (function (_super) {
                                __extends(VirtualDeviceOption$DeviceBackingOption, _super);
                                function VirtualDeviceOption$DeviceBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$DeviceBackingOption';
                                }
                                return VirtualDeviceOption$DeviceBackingOption;
                            }(device.VirtualDeviceOption$BackingOption));
                            device.VirtualDeviceOption$DeviceBackingOption = VirtualDeviceOption$DeviceBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_391.binding || (vim_391.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_392) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthroughOption$DeviceBackingOption = (function (_super) {
                                __extends(VirtualPCIPassthroughOption$DeviceBackingOption, _super);
                                function VirtualPCIPassthroughOption$DeviceBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption$DeviceBackingOption';
                                }
                                return VirtualPCIPassthroughOption$DeviceBackingOption;
                            }(device.VirtualDeviceOption$DeviceBackingOption));
                            device.VirtualPCIPassthroughOption$DeviceBackingOption = VirtualPCIPassthroughOption$DeviceBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_392.binding || (vim_392.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_393) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPortOption$DeviceBackingOption = (function (_super) {
                                __extends(VirtualSerialPortOption$DeviceBackingOption, _super);
                                function VirtualSerialPortOption$DeviceBackingOption() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$DeviceBackingOption';
                                }
                                return VirtualSerialPortOption$DeviceBackingOption;
                            }(device.VirtualDeviceOption$DeviceBackingOption));
                            device.VirtualSerialPortOption$DeviceBackingOption = VirtualSerialPortOption$DeviceBackingOption;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_393.binding || (vim_393.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_394) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vsan;
                    (function (vsan) {
                        var host;
                        (function (host) {
                            var IpConfig = (function () {
                                function IpConfig() {
                                    this._type = 'com.vmware.vim.binding.vim.vsan.host.IpConfig';
                                }
                                return IpConfig;
                            }());
                            host.IpConfig = IpConfig;
                        })(host = vsan.host || (vsan.host = {}));
                    })(vsan = vim.vsan || (vim.vsan = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_394.binding || (vim_394.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_395) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var profile;
                    (function (profile) {
                        var ParameterMetadata = (function () {
                            function ParameterMetadata() {
                                this._type = 'com.vmware.vim.binding.vim.profile.ParameterMetadata';
                            }
                            return ParameterMetadata;
                        }());
                        profile.ParameterMetadata = ParameterMetadata;
                    })(profile = vim.profile || (vim.profile = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_395.binding || (vim_395.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_396) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDevice = (function () {
                                function VirtualDevice() {
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDevice';
                                }
                                return VirtualDevice;
                            }());
                            device.VirtualDevice = VirtualDevice;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_396.binding || (vim_396.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_397) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIPassthrough = (function (_super) {
                                __extends(VirtualPCIPassthrough, _super);
                                function VirtualPCIPassthrough() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough';
                                }
                                return VirtualPCIPassthrough;
                            }(device.VirtualDevice));
                            device.VirtualPCIPassthrough = VirtualPCIPassthrough;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_397.binding || (vim_397.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_398) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualNVDIMM = (function (_super) {
                                __extends(VirtualNVDIMM, _super);
                                function VirtualNVDIMM() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM';
                                }
                                return VirtualNVDIMM;
                            }(device.VirtualDevice));
                            device.VirtualNVDIMM = VirtualNVDIMM;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_398.binding || (vim_398.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_399) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualDisk = (function (_super) {
                                __extends(VirtualDisk, _super);
                                function VirtualDisk() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualDisk';
                                }
                                return VirtualDisk;
                            }(device.VirtualDevice));
                            device.VirtualDisk = VirtualDisk;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_399.binding || (vim_399.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_400) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualCdrom = (function (_super) {
                                __extends(VirtualCdrom, _super);
                                function VirtualCdrom() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom';
                                }
                                return VirtualCdrom;
                            }(device.VirtualDevice));
                            device.VirtualCdrom = VirtualCdrom;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_400.binding || (vim_400.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_401) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualUSB = (function (_super) {
                                __extends(VirtualUSB, _super);
                                function VirtualUSB() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualUSB';
                                }
                                return VirtualUSB;
                            }(device.VirtualDevice));
                            device.VirtualUSB = VirtualUSB;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_401.binding || (vim_401.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_402) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualEthernetCard = (function (_super) {
                                __extends(VirtualEthernetCard, _super);
                                function VirtualEthernetCard() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard';
                                }
                                return VirtualEthernetCard;
                            }(device.VirtualDevice));
                            device.VirtualEthernetCard = VirtualEthernetCard;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_402.binding || (vim_402.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_403) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVmxnet = (function (_super) {
                                __extends(VirtualVmxnet, _super);
                                function VirtualVmxnet() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVmxnet';
                                }
                                return VirtualVmxnet;
                            }(device.VirtualEthernetCard));
                            device.VirtualVmxnet = VirtualVmxnet;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_403.binding || (vim_403.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_404) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVmxnet3 = (function (_super) {
                                __extends(VirtualVmxnet3, _super);
                                function VirtualVmxnet3() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVmxnet3';
                                }
                                return VirtualVmxnet3;
                            }(device.VirtualVmxnet));
                            device.VirtualVmxnet3 = VirtualVmxnet3;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_404.binding || (vim_404.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_405) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVmxnet3Vrdma = (function (_super) {
                                __extends(VirtualVmxnet3Vrdma, _super);
                                function VirtualVmxnet3Vrdma() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVmxnet3Vrdma';
                                }
                                return VirtualVmxnet3Vrdma;
                            }(device.VirtualVmxnet3));
                            device.VirtualVmxnet3Vrdma = VirtualVmxnet3Vrdma;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_405.binding || (vim_405.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_406) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSriovEthernetCard = (function (_super) {
                                __extends(VirtualSriovEthernetCard, _super);
                                function VirtualSriovEthernetCard() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCard';
                                }
                                return VirtualSriovEthernetCard;
                            }(device.VirtualEthernetCard));
                            device.VirtualSriovEthernetCard = VirtualSriovEthernetCard;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_406.binding || (vim_406.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_407) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualController = (function (_super) {
                                __extends(VirtualController, _super);
                                function VirtualController() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualController';
                                }
                                return VirtualController;
                            }(device.VirtualDevice));
                            device.VirtualController = VirtualController;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_407.binding || (vim_407.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_408) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualPCIController = (function (_super) {
                                __extends(VirtualPCIController, _super);
                                function VirtualPCIController() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualPCIController';
                                }
                                return VirtualPCIController;
                            }(device.VirtualController));
                            device.VirtualPCIController = VirtualPCIController;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_408.binding || (vim_408.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_409) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualNVDIMMController = (function (_super) {
                                __extends(VirtualNVDIMMController, _super);
                                function VirtualNVDIMMController() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualNVDIMMController';
                                }
                                return VirtualNVDIMMController;
                            }(device.VirtualController));
                            device.VirtualNVDIMMController = VirtualNVDIMMController;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_409.binding || (vim_409.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_410) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualVideoCard = (function (_super) {
                                __extends(VirtualVideoCard, _super);
                                function VirtualVideoCard() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualVideoCard';
                                }
                                return VirtualVideoCard;
                            }(device.VirtualDevice));
                            device.VirtualVideoCard = VirtualVideoCard;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_410.binding || (vim_410.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim_411) {
            var binding;
            (function (binding) {
                var vim;
                (function (vim) {
                    var vm;
                    (function (vm) {
                        var device;
                        (function (device) {
                            var VirtualSerialPort = (function (_super) {
                                __extends(VirtualSerialPort, _super);
                                function VirtualSerialPort() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vim.binding.vim.vm.device.VirtualSerialPort';
                                }
                                return VirtualSerialPort;
                            }(device.VirtualDevice));
                            device.VirtualSerialPort = VirtualSerialPort;
                        })(device = vm.device || (vm.device = {}));
                    })(vm = vim.vm || (vim.vm = {}));
                })(vim = binding.vim || (binding.vim = {}));
            })(binding = vim_411.binding || (vim_411.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));




